/* eslint-disable no-undef */
import { Auth } from "@/middleware/auth";
import { modules } from "@/enum/UserPermissions";

const breadcrumbs = [
    {
        label: "Dashboard",
        name: "dashboard",
        active: false,
    },
    {
        label: "Organization",
        name: "organization",
        active: false,
    },
];

export default [
    {
        path: "/organization",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        beforeEnter: Auth,
        meta: {
            permission: modules.root_organization,
            title: "Organization",
            submenu: [
                {
                    label: "Profile",
                    name: "organization-profile",
                    active: true,
                    permission: modules.organization_profiles,
                },
                {
                    label: "Locations",
                    name: "organization-locations",
                    active: true,
                    permission: modules.organization_locations,
                },
                {
                    label: "Services",
                    name: "organization-services",
                    active: true,
                    permission: modules.organization_services,
                },
                {
                    label: "Teams",
                    name: "organization-teams",
                    active: true,
                    permission: modules.organization_teams,
                },
                {
                    label: "Documents",
                    name: "organization-documents",
                    active: process.env.MODE !== "production",
                    permission: modules.organization_documents,
                },
            ],
        },

        children: [
            {
                path: "",
                name: "organization",
                redirect: () => {
                    return { name: "organization-profile" };
                },
            },
            {
                path: "/organization/profile",
                name: "organization-profile",
                beforeEnter: Auth,
                meta: {
                    permission: modules.organization_profiles,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Profile",
                            name: "organization-profile",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/organization/profile"
                    ),
            },
            {
                path: "/organization/locations",
                name: "organization-locations",
                beforeEnter: Auth,
                meta: {
                    permission: modules.organization_locations,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Locations",
                            name: "organization-locations",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/organization/locations/index"
                    ),
            },
            {
                path: "/organization/services",
                name: "organization-services",
                beforeEnter: Auth,
                meta: {
                    permission: modules.organization_services,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Services",
                            name: "organization-services",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/organization/services/index.vue"
                    ),
            },
            {
                path: "/organization/teams",
                name: "organization-teams",
                beforeEnter: Auth,
                meta: {
                    permission: modules.organization_teams,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Teams",
                            name: "organization-teams",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/organization/teams/index.vue"
                    ),
            },
            {
                path: "/organization/documents",
                name: "organization-documents",
                beforeEnter: Auth,
                meta: {
                    permission: modules.organization_documents,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Documents",
                            name: "organization-documents",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/organization/documents/index.vue"
                    ),
            },
        ],
    },
];
