import { $notify, $setParams, getMessageFromError } from "@/plugins/Helpers";
import { PEOPLE_RELATION_TYPES } from "@/enum/fundraising";

const defaultParams = {
    page: 1,
    page_size: 10,
    type: PEOPLE_RELATION_TYPES.FOUNDATION,
};
export default {
    state: {
        data: {},
        fundraisingPeoples: [],
    },
    actions: {
        fetchSettings: ({ rootState, commit }) => {
            return axios
                .get(
                    `${rootState.auth.organization.organization}/fundraising/settings/retrieve/`
                )
                .then((res) => {
                    commit("setSettings", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        updateSettings: ({ rootState }, payload) => {
            return axios
                .put(
                    `${rootState.auth.organization.organization}/fundraising/settings/update/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Settings updated successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchFundraisingPeoples: ({ rootState }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `${
                        rootState.auth.organization.organization
                    }/fundraising/people-relation/${$setParams(params)}`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setSettings: (state, payload) => {
            state.data = payload;
        },
        clearSettings: (state) => {
            state.data = {};
        },
        reset: (state) => {
            state.data = {};
            state.fundraisingPeoples = [];
        },
    },
};
