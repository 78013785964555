import { $notify, getMessageFromError, $setParams } from "@/plugins/Helpers";
import { store } from "@/store";
const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
export default {
    state: {
        boards: {},
        governingTeams: [],
        governingTeam: {},
        existingMembers: [],
    },
    actions: {
        fetchBoards: ({ rootState, commit }) => {
            return axios
                .get(
                    `${rootState.auth.organization.organization}/governance/board/all/`
                )
                .then((res) => {
                    commit("setBoards", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `${rootState.auth.organization.organization}/governance/governing/team/create/`,
                    payload.form
                )
                .then(async (res) => {
                    // $notify.success(res.details);
                    // return res;

                    if (res.using_application) {
                        await store.dispatch("user/updatePermissions", {
                            ...payload.permissions,
                            ...{ id: res.permission },
                        });
                    }
                    await store.dispatch("auth/fetchUser");
                    $notify.success(res.details);
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchGoverningTeam: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `${
                        rootState.auth.organization.organization
                    }/governance/governing/team/all/${$setParams(params)}`
                )
                .then((res) => {
                    commit("setGoverningTeams", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchExistingMember: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `${
                        rootState.auth.organization.organization
                    }/governance/governing/team/non/existing_member/${$setParams(
                        params
                    )}`
                )
                .then((res) => {
                    commit("setExistingMembers", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        addExistingMember: ({ rootState }, payload) => {
            return axios
                .post(
                    `${rootState.auth.organization.organization}/governance/governing/team/add/existing_member/`,
                    payload
                )
                .then((res) => {
                    $notify.success(res.details);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `${rootState.auth.organization.organization}/governance/governing/team/edit/${payload.form.id}/`,
                    payload.form
                )
                .then(async (res) => {
                    // $notify.success(res.details);
                    // return res;

                    if (res.using_application) {
                        await store.dispatch("user/updatePermissions", {
                            ...payload.permissions,
                            ...{ id: res.permission },
                        });
                    }
                    await store.dispatch("auth/fetchUser");
                    $notify.success(res.details);
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        downloadTeam: ({ rootState }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/governance/governing/team/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadTeamFile: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/governance/governing/team/upload/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setBoards: (state, payload) => {
            state.boards = payload;
        },
        setGoverningTeams: (state, payload) => {
            state.governingTeams = payload;
        },
        setGoverningTeam: (state, payload) => {
            state.governingTeam = payload;
        },
        clearGoverningTeam: (state) => {
            state.governingTeam = {};
        },
        setExistingMembers: (state, payload) => {
            state.existingMembers = payload;
        },
    },
};
