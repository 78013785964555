import { $notify } from "@/plugins/Helpers";
export default {
    state: {
        settings: {}
    },
    actions: {
        fetchSettings: ({ rootState, commit }) => {
            return axios.get(`${rootState.auth.organization.organization}/governance/settings/retrieve/`)
                .then((res) => {
                    commit("setSettings", res)
                    return res;
                })
        },
        updateMemberDetails: ({ rootState }, payload) => {
            return axios.put(`${rootState.auth.organization.organization}/governance/settings/board-details/update/`, payload)
                .then((res) => {
                    return res;
                })
        },
        updateSettings: ({ rootState }, payload) => {
            return axios.put(`${rootState.auth.organization.organization}/governance/settings/update/`, payload)
                .then((res) => {
                    $notify.success(
                        "Governance settings updated."
                    );
                    return res;
                })
        },
        deleteBoardDetails: ({ rootState }, payload) => {
            return axios.delete(`${rootState.auth.organization.organization}/governance/settings/board-details/delete/${payload.id}/?query=${payload.type}`)
                .then((res) => {
                    return res;
                })
        },
        deleteCommittee: ({ rootState }, payload) => {
            return axios.delete(`${rootState.auth.organization.organization}/governance/settings/board-committee/delete/${payload.id}/?query=${payload.type}`)
                .then((res) => {
                    return res;
                })
        }
    },
    mutations: {
        setSettings: (state, payload) => {
            state.settings = payload;
        },
        clearSettings: (state) => {
            state.settings = {};
        },
        reset: (state) => {
            state.settings = {};
        }
    },
}