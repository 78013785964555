import { $alert, $notify, getMessageFromError } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
};
export default {
    state: {
        documents: [],
    },

    actions: {
        fetch: (
            { rootState, commit },
            payload = { module: "", params: {} }
        ) => {
            const params = { ...defaultParams, ...payload.params };
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/corporate-documents/drive/details/${payload.module}/?page=${params.page}&page_size=${params.page_size}`
                )
                .then((res) => {
                    commit("setDocuments", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/corporate-documents/drive/create/`,
                    payload
                )
                .then((res) => {
                    const totalSuccessCount = Number(
                        res.total_documents - res.total_duplicate_documents
                    );
                    if (totalSuccessCount > 0) {
                        $notify.success(
                            `${totalSuccessCount} Documents synced successfully`
                        );
                    }

                    if (
                        res.total_duplicate_documents &&
                        res.duplicate_documents_list.length
                    ) {
                        $alert.error({
                            title: "Duplicate Sync",
                            customClass: { popup: "!w-[36em] !rounded-lg" },
                            html: `<div class="text-left">
                            <span class="text-primary-3 text-base">
                            Some selected documents are already synced with
                                ${process.env.APP_NAME}
                            </span>
                            <ul class="text-sm mt-2"> ${res.duplicate_documents_list
                                .map(
                                    (item) =>
                                        `<li class="pl-4 relative before:content-[''] before:w-2 before:h-2 before:rounded-full before:bg-red-500 before:absolute before:left-0 before:top-1.5 mt-2">${item}</li>`
                                )
                                .join("")}
                            </ul>
                        </div>`,
                        });
                    }

                    return res.response;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/corporate-documents/drive/document/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Document updated successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setDocuments: (state, payload) => {
            state.documents = payload;
        },
        clearDocuments: (state) => {
            state.documents = [];
        },
        reset: (state) => {
            state.documents = [];
        },
    },
};
