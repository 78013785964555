const permissions = {
    CREATE: "C",
    VIEW: "R",
    UPDATE: "U",
    DELETE: "D",
    USER: "A",
    CRUD: "CRUD",
};

const authorityLevel = {
    MANAGER: "CRUDA",
    CONTRIBUTOR: "CRUD",
    CONTENT_MANAGER: "CRU",
    VIEWER: "R",
};

const authorityLevelMap = {
    manager: "MANAGER",
    contributor: "CONTRIBUTOR",
    contentManager: "CONTENT_MANAGER",
    viewer: "VIEWER",
};

const permissionsMap = {
    root_organization: "Organization",
    root_people: "People",
    root_partner: "Partnership",
    root_program: "Programs & Services",
    root_finance: "Finance",
    root_settings: "Settings",
    root_supports: "Support",
    root_fundraising: "Fundraising",
    root_governance: "Governance",
    root_report: "Reporting",
};

const modules = {
    // organization
    root_organization: "root_organization",
    organization_profiles: "organization_profiles",
    organization_locations: "organization_locations",
    organization_service_areas: "organization_service_areas",
    organization_services: "organization_services",
    organization_teams: "organization_teams",
    organization_documents: "organization_documents",
    organization_departments: "organization_departments",
    organization_settings: "organization_settings",
    organization_types: "organization_types",

    // people
    root_people: "root_people",
    peoples: "peoples",
    people_settings: "people_settings",
    people_insight: "people_insight",
    people_teams: "people_teams",
    people_documents: "people_documents",
    people_settings_departments: "people_settings_departments",
    people_settings_designations: "people_settings_designations",
    people_settings_special_accommodations:
        "people_settings_special_accommodations",

    // partnership
    root_partner: "root_partner",
    partners: "partners",
    partner_insight: "partner_insight",
    partner_settings: "partner_settings",
    partner_organizations: "partner_organizations",
    partner_outside_experts: "partner_outside_experts",
    partner_documents: "partner_documents",
    permission_templates: "permission_templates",

    // programs and services
    root_program: "root_program",
    programs: "programs",
    program_and_service_settings: "program_and_service_settings",
    program_and_service_insight: "program_and_service_insight",
    program_and_service_partners: "program_and_service_partners",
    program_and_service_program_activities:
        "program_and_service_program_activities",
    program_and_service_settings_external_organizations:
        "program_and_service_settings_external_organizations",
    program_and_service_settings_programs:
        "program_and_service_settings_programs",
    program_and_service_settings_services:
        "program_and_service_settings_services",
    program_and_service_settings_intake: "program_and_service_settings_intake",
    program_and_service_intake_data: "program_and_service_intake_data",
    program_and_service_intake_form: "program_and_service_intake_form",
    program_and_service_documents: "program_and_service_documents",

    // intake forms
    intakes: "intakes",
    intake_forms: "intake_forms",

    // finance
    root_finance: "root_finance",
    finance_documents: "finance_documents",
    finance_expenses: "finance_expenses",
    finance_insight: "finance_insight",
    finance_revenues: "finance_revenues",
    finance_settings: "finance_settings",
    finance_settings_general_info: "finance_settings_general_info",
    finance_settings_financial_practices:
        "finance_settings_financial_practices",
    finance_settings_expense_line_items: "finance_settings_expense_line_items",
    finance_settings_departments: "finance_settings_departments",
    finance_settings_programs: "finance_settings_programs",

    // settings
    root_settings: "root_settings",
    settings_user_and_permissions: "settings_user_and_permissions",
    users_and_permissions: "users_and_permissions",
    settings_billing: "settings_billing",
    settings_security: "settings_security",
    settings_signin: "settings_signin",

    // supports
    root_supports: "root_supports",

    // reporting
    root_report: "root_report",

    // fundraising
    root_fundraising: "root_fundraising",
    fundraising_corporations: "fundraising_corporations",
    fundraising_documents: "fundraising_documents",
    fundraising_foundations: "fundraising_foundations",
    fundraising_governments: "fundraising_governments",
    fundraising_individuals: "fundraising_individuals",
    fundraising_insight: "fundraising_insight",
    fundraising_settings: "fundraising_settings",

    // governance
    root_governance: "root_governance",

    // others
    advocacies: "advocacies",
    benefits: "benefits",
    budgets: "budgets",
    cities: "cities",
    countries: "countries",
    contact_types: "contact_types",
    designations: "designations",
    departments: "departments",
    expenses: "expenses",
    fundings: "fundings",
    languages: "languages",
    line_items: "line_items",
    locations: "locations",
    outcomes: "outcomes",
    outside_experts: "outside_experts",
    projects: "projects",
    program_activities: "program_activities",
    program_activity_types: "program_activity_types",
    revenues: "revenues",
    special_accommodations: "special_accommodations",
    service_areas: "service_areas",
    states: "states",
    services: "services",
    zips: "zips",
};

export {
    permissions,
    modules,
    authorityLevel,
    authorityLevelMap,
    permissionsMap,
};
