import { Auth } from "@/middleware/auth";
import { modules } from "@/enum/UserPermissions";

const breadcrumbs = [
    {
        label: "Dashboard",
        name: "dashboard",
        active: false,
    },
    {
        label: "Reporting",
        name: "reporting",
        active: false,
    },
];

export default [
    {
        path: "/reporting",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        beforeEnter: Auth,
        meta: {
            title: "Reporting",
            permission: modules.root_report,
            submenu: [],
        },
        children: [
            {
                path: "/reporting",
                name: "reporting",
                beforeEnter: Auth,
                meta: {
                    breadcrumbs: [...breadcrumbs],
                    permission: modules.root_report,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/reporting/index.vue"
                    ),
            },
        ],
    },
];
