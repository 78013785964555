// import { Auth } from "@/middleware/auth";

const breadcrumbs = [
    {
        label: "Dashboard",
        name: "dashboard",
        active: false,
    }
]

export default [
    {
        path: "/handbook",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        // beforeEnter: Auth,
        meta: {
            title: "Handbook",
            submenu: [],
        },
        children: [
            {
                path: "/handbook",
                name: "handbook",
                // beforeEnter: Auth,
                meta: {
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Handbook",
                            name: "handbook",
                            active: true,
                        }
                    ]
                },
                component: () => import(/* webpackChunkName: "dashboard" */ "@/views/account/handbook/index.vue")
            }
        ]
    }
]