import axios from "axios";
import { store } from "@/store";

axios.defaults.baseURL = `${process.env.API_BASE_URL}/${process.env.API_VERSION}`;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

if (
    !axios.defaults.headers.common["Authorization"] &&
    store.state?.auth?.isLoggedIn &&
    store.state?.auth?.token?.access
) {
    axios.defaults.headers.common[
        "Authorization"
    ] = `JWT ${store.state.auth.token?.access}`;
}

// axios.interceptors.request.use(
//     (config) => {
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

axios.interceptors.response.use((res) => {
    return res.data;
});

window.axios = axios;

// export default axios;
