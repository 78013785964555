import axios from "axios";

export default {
    state: {
        data: {},
    },
    actions: {
        fetchRevenueExpense: ({ rootState }) => {
            return axios
                .get(
                    `${rootState.auth.organization.organization}/finances/chart/revenue/expense/`
                )
                .then((res) => {
                    return res;
                });
        },
        fetchRevenueCompositions: ({ rootState }) => {
            return axios
                .get(
                    `${rootState.auth.organization.organization}/finances/chart/revenue/composition/`
                )
                .then((res) => {
                    return res;
                });
        },
        fetchExpenseCompositions: ({ rootState }) => {
            return axios
                .get(
                    `${rootState.auth.organization.organization}/finances/chart/expense/composition/`
                )
                .then((res) => {
                    return res;
                });
        },
        fetchProgram: ({ rootState }) => {
            return axios
                .get(
                    `${rootState.auth.organization.organization}/finances/chart/revenue/expense/program/`
                )
                .then((res) => {
                    return res;
                });
        },
    },

    mutations: {
        reset: (state) => {
            state.data = {};
        },
    },
};
