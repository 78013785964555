import { $notify, $setParams, getMessageFromError } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
export default {
    state: {
        foundations: [],
        foundation: {},
    },
    actions: {
        fetch: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `${
                        rootState.auth.organization.organization
                    }/fundraising/foundations/all/${$setParams(params)}`
                )
                .then((res) => {
                    commit("setFoundations", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/fundraising/foundations/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Foundation created successfully");
                    return res;
                });
        },
        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/fundraising/foundations/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Foundation updated successfully");
                    return res;
                });
        },
        downloadFoundation: ({ rootState })=>{
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/fundraising/foundations/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadFoundationFile: ({ rootState },payload)=>{
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/fundraising/foundations/upload/`,payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        }
    },
    mutations: {
        setFoundations: (state, payload) => {
            state.foundations = payload;
        },
        clearFoundations: (state) => {
            state.foundations = [];
        },
        setFoundation: (state, payload) => {
            state.foundation = payload;
        },
        clearFoundation: (state) => {
            state.foundation = {};
        },
        reset: (state) => {
            state.foundations = [];
            state.foundation = {};
        },
    },
};
