import { Auth } from "@/middleware/auth";
import { modules } from "@/enum/UserPermissions";

const breadcrumbs = [
    {
        label: "Dashboard",
        name: "dashboard",
        active: false,
    },
    {
        label: "Partnership",
        name: "partnership",
        active: false,
    },
];

export default [
    {
        path: "/partnership",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        beforeEnter: Auth,

        meta: {
            permission: modules.root_partner,
            title: "Partnership",
            submenu: [
                {
                    label: "Insight",
                    name: "partnership-insight",
                    active: true,
                    permission: modules.partner_insight,
                },
                {
                    label: "Partners",
                    name: "partnership-partners",
                    active: true,
                    permission: modules.partners,
                },
                {
                    label: "Outside Experts",
                    name: "partnership-outside-experts",
                    active: true,
                    permission: modules.partner_outside_experts,
                },
                {
                    label: "Documents",
                    name: "partnership-documents",
                    active: process.env.MODE !== "production",
                    permission: modules.partner_documents,
                },
                {
                    label: "Settings",
                    name: "partnership-settings",
                    active: true,
                    permission: modules.partner_settings,
                },
            ],
        },

        children: [
            {
                path: "",
                name: "partnership",
                redirect: () => {
                    return { name: "partnership-insight" };
                },
            },
            {
                path: "/partnership/insight",
                name: "partnership-insight",
                beforeEnter: Auth,
                meta: {
                    permission: modules.partner_insight,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Insight",
                            name: "partnership-insight",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/partnership/insights/index.vue"
                    ),
            },
            {
                path: "/partnership/partners",
                name: "partnership-partners",
                beforeEnter: Auth,
                meta: {
                    permission: modules.partners,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Partners",
                            name: "partnership-partners",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/partnership/partners/index.vue"
                    ),
            },
            {
                path: "/partnership/outside-experts",
                name: "partnership-outside-experts",
                beforeEnter: Auth,
                meta: {
                    permission: modules.partner_outside_experts,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Outside Experts",
                            name: "partnership-outside-experts",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/partnership/outside-experts/index.vue"
                    ),
            },
            {
                path: "/partnership/documents",
                name: "partnership-documents",
                beforeEnter: Auth,
                meta: {
                    permission: modules.partner_documents,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Documents",
                            name: "partnership-documents",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/partnership/documents/index.vue"
                    ),
            },
            {
                path: "/partnership/settings",
                name: "partnership-settings",
                beforeEnter: Auth,
                meta: {
                    permission: modules.partner_settings,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "settings",
                            name: "partnership-settings",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/partnership/settings/index.vue"
                    ),
            },
        ],
    },
];
