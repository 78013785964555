import { Auth } from "@/middleware/auth";
import { modules } from "@/enum/UserPermissions";

const breadcrumbs = [
    {
        label: "Dashboard",
        name: "dashboard",
        active: false,
    },
    {
        label: "Finance",
        name: "finance",
        active: false,
    },
];

export default [
    {
        path: "/finance",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        beforeEnter: Auth,
        meta: {
            permission: modules.root_finance,
            title: "Finance",
            submenu: [
                {
                    label: "Insight",
                    name: "finance-insight",
                    active: true,
                    permission: modules.finance_insight,
                },
                {
                    label: "Revenue",
                    name: "finance-revenue",
                    active: true,
                    permission: modules.finance_revenues,
                },
                {
                    label: "Expenses",
                    name: "finance-expenses",
                    active: true,
                    permission: modules.finance_expenses,
                },
                {
                    label: "Documents",
                    name: "finance-documents",
                    active: process.env.MODE !== "production",
                    permission: modules.finance_documents,
                },
                {
                    label: "Settings",
                    name: "finance-settings",
                    active: true,
                    permission: modules.finance_settings,
                },
            ],
        },

        children: [
            {
                path: "",
                name: "finance",
                redirect: () => {
                    return { name: "finance-insight" };
                },
            },
            {
                path: "/finance/insight",
                name: "finance-insight",
                beforeEnter: Auth,
                meta: {
                    permission: modules.finance_insight,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Insight",
                            name: "finance-insight",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/finance/insight/index.vue"
                    ),
            },
            {
                path: "/finance/revenue",
                name: "finance-revenue",
                beforeEnter: Auth,
                meta: {
                    permission: modules.finance_revenues,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Revenue",
                            name: "finance-revenue",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/finance/revenue/index.vue"
                    ),
            },
            {
                path: "/finance/expenses",
                name: "finance-expenses",
                beforeEnter: Auth,
                meta: {
                    permission: modules.finance_expenses,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Expenses",
                            name: "finance-expenses",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/finance/expenses/index.vue"
                    ),
            },
            {
                path: "/finance/documents",
                name: "finance-documents",
                beforeEnter: Auth,
                meta: {
                    permission: modules.finance_documents,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Documents",
                            name: "finance-documents",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/finance/documents/index.vue"
                    ),
            },
            {
                path: "/finance/settings",
                name: "finance-settings",
                beforeEnter: Auth,
                meta: {
                    permission: modules.finance_settings,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Settings",
                            name: "finance-settings",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/finance/settings/index.vue"
                    ),
            },
        ],
    },
];
