import { $setParams, $notify, getMessageFromError } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
export default {
    state: {
        revenues: [],
        revenue: {},
        revenueSources: [],
    },
    actions: {
        fetchRevenues: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${
                        rootState.auth.organization.organization
                    }/finances/revenue/all/${$setParams(params)}`
                )
                .then(async (response) => {
                    await commit("setRevenues", response.results);
                    return response;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/finances/revenue/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Revenue created successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        update: ({ rootState }, payload) => {
            payload.organization = rootState.auth.organization.organization;
            return axios
                .put(
                    `/${payload.organization}/finances/revenue/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Revenue updated successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchRevenueSources: ({ rootState, commit }, params = {}) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/finances/settings/revenue/source/all/?is_checked=${params.is_checked}`
                )
                .then(async (response) => {
                    await commit("setRevenueSources", response);
                    return response;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        downloadRevenue: ({ rootState })=>{
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/finances/revenue/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadRevenueFile: ({ rootState },payload)=>{
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/finances/revenue/upload/`,payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        }
    },
    mutations: {
        setRevenues: (state, data) => {
            state.revenues = data;
        },
        clearRevenues: (state) => {
            state.revenues = [];
        },
        setRevenue: (state, data) => {
            state.revenue = data;
        },
        clearRevenue: (state) => {
            state.revenue = {};
        },
        setRevenueSources: (state, data) => {
            state.revenueSources = data;
        },
        clearRevenueSources: (state) => {
            state.revenueSources = [];
        },
        reset: (state) => {
            state.revenues = [];
            state.revenue = {};
        },
    },
};
