export default [
    {
        path: "/ui",
        component: () => import(/* webpackChunkName: "auth" */ "@/views/ui"),
    },
    {
        path: "/chart",
        component: () => import(/* webpackChunkName: "auth" */ "@/views/ui/chartExample"),
    },
];
