import { Auth } from "@/middleware/auth";

export default [
    {
        path: "/",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        beforeEnter: Auth,
        meta: {
            title: "Dashboard",
            permission: "all",
            submenu: [],
        },
        children: [
            {
                path: "",
                redirect: () => {
                    return { name: "dashboard" };
                },
            },
            {
                path: "/dashboard",
                name: "dashboard",
                beforeEnter: Auth,
                meta: {
                    permission: "all",
                    title: "Dashboard",
                    breadcrumbs: [],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard"
                    ),
            },
        ],
    },
];
