import { $setParams, getMessageFromError } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};

export default {
    state: {
        teams: [],
        team: {},
    },
    actions: {
        fetchTeams: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            const orgId = rootState.auth.organization.organization;
            return axios
                .get(`/user/team/${orgId}/${$setParams(params)}`)
                .then(async (response) => {
                    await commit("setTeams", response.results);
                    return response;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        create: ({ rootState }, payload) => {
            payload.organization = rootState.auth.organization.organization;
            return axios
                .post(`/user/team/${payload.organization}/create/`, payload)
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        update: ({ rootState }, payload) => {
            payload.organization = rootState.auth.organization.organization;
            return axios
                .put(
                    `/user/team/${payload.organization}/edit/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        sendInvitation: ({ rootState, state }, payload) => {
            payload.using_application = true;
            payload.people = state.team.id;
            return axios
                .post(
                    `/user/team/${rootState.auth.organization.organization}/invite/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        deleteUser: ({ rootState},payload)=>{
            return axios.delete(`organization/not/user/${rootState.auth.organization.organization}/deactivate/${payload}`)
            .then(res=>{
                return res;
            })
        },
        downloadOrganizationTeam: ({ rootState })=>{
            return axios
                .get(
                    `/user/team/${rootState.auth.organization.organization}/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadOrgTeamFile: ({ rootState },payload)=>{
            return axios
                .post(
                    `/user/team/${rootState.auth.organization.organization}/upload/`,payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        }
    },
    mutations: {
        setTeams: (state, data) => {
            state.teams = data;
        },
        clearTeams: (state) => {
            state.teams = [];
        },
        setTeam: (state, data) => {
            state.team = data;
        },
        clearTeam: (state) => {
            state.team = {};
        },
        reset: (state) => {
            state.teams = [];
            state.team = {};
        },
    },
};
