import { createRouter, createWebHistory } from "vue-router";

import Auth from "./modules/auth";
import Dashboard from "./modules/dashboard";
import Organization from "./modules/organization";
import People from "./modules/people";
import ProgramServices from "./modules/programServices";
import Finance from "./modules/finance";
import Fundraising from "./modules/fundraising";
import Governance from "./modules/governance";
import Partnerships from "./modules/partnerships";
import Welcome from "./modules/welcome";
import Settings from "./modules/settings";
import Support from "./modules/support";
import Account from "./modules/account";
import Reporting from "./modules/reporting";
import Handbook from "./modules/handbook";
import Ui from "./modules/ui";
import { store } from "@/store";

const routes = [
    ...Dashboard,
    ...Auth,
    ...Organization,
    ...People,
    ...ProgramServices,
    ...Finance,
    ...Fundraising,
    ...Governance,
    ...Partnerships,
    ...Welcome,
    ...Settings,
    ...Support,
    ...Account,
    ...Reporting,
    ...Handbook,
    ...Ui,
    ...[
        {
            path: "/callback",
            component: () =>
                import(
                    /* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"
                ),
            meta: {
                title: "",
                permission: "all",
                submenu: [],
            },
            children: [
                {
                    path: "",
                    name: "callback",
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard" */ "@/views/callback"
                        ),
                },
            ],
        },
        {
            path: "/:pathMatch(.*)*",
            name: "404",
            meta: {
                breadcrumbs: [],
                submenu: [],
                title: "404",
            },
            component: () =>
                import(/* webpackChunkName: "dashboard" */ "@/views/404"),
        },
    ],
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0, behavior: "smooth" };
    },
});

router.beforeEach((to, from, next) => {
    document.title = to?.meta?.title;
    if (store.state?.auth?.isLoggedIn) {
        store.dispatch("auth/fetchUser");
    }

    // if (!to.path.includes("programs-services")) {
    //     store.commit("programsServicesSettings/clearSettings");
    // }

    next();
});

export default router;
