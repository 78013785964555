import { Auth } from "@/middleware/auth";
import { modules } from "@/enum/UserPermissions";
import { store } from "@/store";
import { $hasPermission } from "@/plugins/Helpers";

const breadcrumbs = [
    {
        label: "Dashboard",
        name: "dashboard",
        active: false,
    },
    {
        label: "Program and Services",
        name: "programs-services",
        active: false,
    },
];
const fetchSettings = async (to, from, next) => {
    // if (!store.state.auth.isLoggedIn || !store.state.auth.token) return;
    if ($hasPermission.any(modules.program_and_service_settings)) {
        await store
            .dispatch("programsServicesSettings/fetchSettings")
            .then((res) => {
                to.meta.submenu.map((submenu) => {
                    if (submenu.name === "programs-services-partners") {
                        submenu.active = res.is_partner;
                    }
                });
            });
    }
    next();
};

export default [
    {
        path: "/programs-services",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        beforeEnter: [Auth, fetchSettings],
        meta: {
            permission: modules.root_program,
            title: "Program and Services",
            submenu: [
                {
                    label: "Insight",
                    name: "programs-services-insight",
                    active: true,
                    permission: modules.program_and_service_insight,
                },
                {
                    label: "Data Form",
                    name: "programs-services-data-form",
                    active: true,
                    permission: modules.program_and_service_intake_data,
                },
                {
                    label: "Program Activity",
                    name: "programs-services-program-activity",
                    active: true,
                    permission: modules.program_and_service_program_activities,
                },
                {
                    label: "Documents",
                    name: "programs-services-documents",
                    active: process.env.MODE !== "production",
                    permission: modules.program_and_service_documents,
                },
                {
                    label: "Partners",
                    name: "programs-services-partners",
                    active: false,
                    permission: modules.program_and_service_partners,
                },
                {
                    label: "Settings",
                    name: "programs-services-settings",
                    active: true,
                    permission: modules.program_and_service_settings,
                },
                {
                    label: "Form Builder",
                    name: "programs-services-form-builder",
                    active: true,
                    permission: modules.program_and_service_intake_form,
                },
                {
                    label: "Program",
                    name: "programs-services-program",
                    active: true,
                    permission: modules.program_and_service_intake_form,
                },
            ],
        },

        children: [
            {
                path: "",
                name: "programs-services",
                redirect: () => {
                    return { name: "programs-services-insight" };
                },
            },
            {
                path: "/programs-services/insight",
                name: "programs-services-insight",
                beforeEnter: Auth,
                meta: {
                    permission: modules.program_and_service_insight,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Insight",
                            name: "programs-services-insight",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/programs-services/insight/index.vue"
                    ),
            },
            {
                path: "/programs-services/data-form",
                name: "programs-services-data-form",
                beforeEnter: Auth,
                meta: {
                    permission: modules.program_and_service_intake_data,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Data Form",
                            name: "programs-services-data-form",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/programs-services/data-form/index.vue"
                    ),
            },
            {
                path: "/programs-services/program-activity",
                name: "programs-services-program-activity",
                beforeEnter: Auth,
                meta: {
                    permission: modules.program_and_service_program_activities,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Program Activity",
                            name: "programs-services-program-activity",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/programs-services/program-activity/index.vue"
                    ),
            },
            {
                path: "/programs-services/documents",
                name: "programs-services-documents",
                beforeEnter: Auth,
                meta: {
                    permission: modules.program_and_service_documents,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Documents",
                            name: "programs-services-documents",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/programs-services/documents/index.vue"
                    ),
            },
            {
                path: "/programs-services/partners",
                name: "programs-services-partners",
                beforeEnter: Auth,
                meta: {
                    permission: modules.program_and_service_partners,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Partners",
                            name: "programs-services-partners",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/programs-services/partners/index.vue"
                    ),
            },
            {
                path: "/programs-services/settings",
                name: "programs-services-settings",
                beforeEnter: Auth,
                meta: {
                    permission: modules.program_and_service_settings,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Settings",
                            name: "programs-services-settings",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/programs-services/settings/index.vue"
                    ),
            },
            {
                path: "/programs-services/form-builder",
                name: "programs-services-form-builder",
                beforeEnter: Auth,
                meta: {
                    permission: modules.program_and_service_intake_form,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Form Builder",
                            name: "programs-services-form-builder",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/programs-services/form-builder/index.vue"
                    ),
            },
            {
                path: "/programs-services/program",
                name: "programs-services-program",
                beforeEnter: Auth,
                meta: {
                    permission: modules.program_and_service_intake_form,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Program",
                            name: "programs-services-program",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/programs-services/program/index.vue"
                    ),
            },
        ],
    },
];
