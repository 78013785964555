import { Auth } from "@/middleware/auth";
import { modules } from "@/enum/UserPermissions";

const breadcrumbs = [
    {
        label: "Dashboard",
        name: "dashboard",
        active: false,
    },
    {
        label: "Governance",
        name: "governance",
        active: false,
    },
];

export default [
    {
        path: "/governance",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        beforeEnter: Auth,
        meta: {
            title: "Governance",
            submenu: [
                {
                    label: "Insight",
                    name: "governance-insight",
                    active: true,
                    permission: modules.root_governance,
                },
                {
                    label: "Governing Team",
                    name: "governance-governing-team",
                    active: true,
                    permission: modules.root_governance,
                },
                {
                    label: "Committees",
                    name: "governance-committees",
                    active: true,
                    permission: modules.root_governance,
                },
                {
                    label: "Compliance",
                    name: "governance-compliance",
                    active: true,
                    permission: modules.root_governance,
                },
                {
                    label: "Settings",
                    name: "governance-settings",
                    active: true,
                    permission: modules.root_governance,
                },
            ],
        },
        children: [
            {
                path: "",
                name: "governance",
                redirect: () => {
                    return { name: "governance-insight" };
                },
            },
            {
                path: "/governance/insight",
                name: "governance-insight",
                beforeEnter: Auth,
                meta: {
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Insight",
                            name: "governance-insight",
                            active: true,
                        }
                    ],
                    permission: modules.root_governance,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/governance/insight/index.vue"
                    ),
            },
            {
                path: "/governance/committees",
                name: "governance-committees",
                beforeEnter: Auth,
                meta: {
                    breadcrumbs: [...breadcrumbs,
                    {
                        label: "Committees",
                        name: "governance-committees",
                        active: true,
                    }],
                    permission: modules.root_governance,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/governance/committees/index.vue"
                    ),
            },
            {
                path: "/governance/compliance",
                name: "governance-compliance",
                beforeEnter: Auth,
                meta: {
                    breadcrumbs: [...breadcrumbs,
                    {
                        label: "Compliance",
                        name: "governance-compliance",
                        active: true,
                    }],
                    permission: modules.root_governance,
                },
                component: () => import("@/views/dashboard/governance/compliance/index.vue")
            },
            {
                path: "/governance/settings",
                name: "governance-settings",
                beforeEnter: Auth,
                meta: {
                    breadcrumbs: [...breadcrumbs,
                    {
                        label: "Settings",
                        name: "governance-settings",
                        active: true,
                    }],
                    permission: modules.root_governance,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/governance/settings/index.vue"
                    ),
            },
            {
                path: "/governance/governing-team",
                name: "governance-governing-team",
                beforeEnter: Auth,
                meta: {
                    breadcrumbs: [...breadcrumbs,
                    {
                        label: "Governing Team",
                        name: "governance-governing-team",
                        active: true,
                    }],
                    permission: modules.root_governance,
                },
                component: () => import( /* webpackChunkName: "dashboard" */ "@/views/dashboard/governance/governing-team/index.vue"),

            }
        ],
    },
];
