export default {
    namespaced: true,
    state: {},
    actions: {
        fetchFormData: ({ rootState }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/intakes/chart/intake-form-vs-intake-data/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchProgramAttendees: ({ rootState }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/intakes/chart/program-vs-attendees/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchProgramFormdata: ({ rootState }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/intakes/chart/program-service-vs-data/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mutations: {
        reset: () => {},
    },
};
