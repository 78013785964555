import { $notify, getMessageFromError, $setParams } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
export default {
    state: {
        experts: [],
        expert: {},
    },

    actions: {
        fetch: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${
                        rootState.auth.organization.organization
                    }/partnerships/outside-expert/all/${$setParams(params)}`
                )
                .then((res) => {
                    commit("setExperts", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/partnerships/outside-expert/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success(res.details);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/partnerships/outside-expert/edit/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        downloadOutsideExpert: ({ rootState })=>{
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/partnerships/download/outside-expert/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadOutsideExpretFile: ({ rootState },payload)=>{
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/partnerships/upload/outside-expert/`,payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        }
    },
    mutations: {
        setExperts: (state, payload) => {
            state.experts = payload;
        },
        clearExperts: (state) => {
            state.experts = [];
        },
        setExpert: (state, payload) => {
            state.expert = payload;
        },
        clearExpert: (state) => {
            state.expert = {};
        },
        reset: (state) => {
            state.experts = [];
            state.expert = {};
        },
    },
};
