export const PEOPLE_RELATION_TYPES = {
    FOUNDATION: 1,
    GOVERNMENT: 2,
    CORPORATION: 3,
    INDIVIDUAL_DONOR: 4,
    FUND_APPROVAL: 5,
    FUND_TRACKER: 6,
};

export const FUNDING_STATUS = {
    LOST: "lost",
    PLEDGED: "pledged",
    ON_GOING: "on_going",
    PENDING: "pending",
    RECEIVED: "received",
    REJECTED: "rejected",
};

export const FUNDING_STATUS_MAP = {
    lost: "Lost",
    pledged: "Pledged",
    on_going: "Ongoing",
    pending: "Pending",
    received: "Received",
    rejected: "Rejected",
};

export const NECESSITY_TYPES = {
    NO: 0,
    YES: 1,
    NOT_YET: 2,
};

export const NECESSITY_TYPES_MAP = {
    0: "No",
    1: "Yes",
    2: "Not Yet",
};

export const FUNDRAISING_SUBMODULES = {
    FOUNDATIONS: "foundations",
    CORPORATIONS: "corporations",
    GOVERNMENT: "government",
    INDIVIDUALS: "individuals",
};

export const FUNDRAISING_SUBMODULES_MAP = {
    foundations: "Foundations",
    corporations: "Corporations",
    government: "Government",
    individuals: "Individuals",
};
