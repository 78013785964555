import { $notify, getMessageFromError } from "@/plugins/Helpers";

export default {
    state: {
        data: {},
    },
    actions: {
        fetchOrganization: ({ rootState, commit }) => {
            return axios
                .get(
                    `/organization/profile/${rootState.auth?.organization?.id}/`
                )
                .then((res) => {
                    commit("setOrganization", res);
                    return res;
                })
                .catch((err) => {
                    getMessageFromError(err);
                });
        },
        updateOrganization: ({ commit }, payload) => {
            return axios
                .patch(
                    `/organization/profile/edit/${payload.organization}/`,
                    payload
                )
                .then((res) => {
                    commit("setOrganization", res);
                });
        },
        fetchTaxExemption: ({ rootState }) => {
            return axios
                .get(
                    `/billings/${rootState.auth.organization.organization}/tax/exemption/`
                )
                .then((res) => {
                    return res;
                });
        },
        saveTaxExemption: ({ rootState }, payload) => {
            return axios
                .post(
                    `/billings/${rootState.auth.organization.organization}/tax/exemption/`, payload
                )
                .then((res) => {
                    $notify.success(
                        "Successfully applied for tax exemption",
                    );
                    return res;
                });
        },
    },
    mutations: {
        setOrganization: (state, payload) => {
            state.data = payload;
        },
        clearOrganization: (state) => {
            state.data = {};
        },
        reset: (state) => {
            state.data = {};
        },
    },
};
