export default function loadCustomMeta(attributes = {}) {
    return new Promise((resolve, reject) => {
        const meta = document.createElement("meta");
        if (Object.keys(attributes).length) {
            Object.keys(attributes).map((key) => {
                meta.setAttribute(key, attributes[key]);
            });
        }

        meta.onload = (...args) => {
            resolve(...args);
        };

        meta.onerror = (error) => {
            reject(error);
        };

        document.head.appendChild(meta);
    });
}
