import { getMessageFromError, $setParams } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
export default {
    state: {
        locations: [],
        location: {},
    },

    actions: {
        fetch: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${
                        rootState.auth.organization.organization
                    }/organization-locations/all/${$setParams(params)}`
                )
                .then((res) => {
                    commit("setLocations", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/organization-locations/create/`,
                    payload
                )
                .then((res) => {
                    return res.results;
                })
                // .catch((error) => {
                //    getMessageFromError(error);
                // });
        },
        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/organization-locations/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        downloadLocation: ({ rootState })=>{
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/organization-locations/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadFile: ({ rootState },payload)=>{
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/organization-locations/upload/`,payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        }
    },
    mutations: {
        setLocations: (state, payload) => {
            state.locations = payload;
        },
        clearLocations: (state) => {
            state.locations = [];
        },
        setLocation: (state, payload) => {
            state.location = payload;
        },
        clearLocation: (state) => {
            state.location = {};
        },
        reset: (state) => {
            state.locations = [];
            state.location = {};
        },
    },
};
