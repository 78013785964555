
import { $notify, getMessageFromError } from "@/plugins/Helpers";
export default {
    state: {
        settings: {},
        compliance: {},
    },
    actions: {
        fetchSettings: ({ rootState, commit }) => {
            return axios.get(`${rootState.auth.organization.organization}/governance/compliance/setting/retrieve/`)
                .then(res => {
                    commit("setSettings", res)
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                })
        },
        updateSettings: ({ rootState }, payload) => {
            return axios.put(`${rootState.auth.organization.organization}/governance/compliance/setting/update/${payload.id}/`, payload)
                .then(res => {
                    $notify.success("Compliance settings updated successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                })
        },
        fetch: ({ rootState, commit }) => {
            return axios.get(`${rootState.auth.organization.organization}/governance/compliance/retrieve/`)
                .then((res) => {
                    commit("setCompliance", res)
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                })
        },
        update: ({ rootState }, payload) => {
            return axios.put(`${rootState.auth.organization.organization}/governance/compliance/update/${payload.id}/`, payload)
                .then(res => {
                    $notify.success("Compliance updated successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                })
        },
    },
    mutations: {
        setSettings: (state, payload) => {
            state.settings = payload;
        },
        setCompliance: (state, payload) => {
            state.compliance = payload;
        }
    },
}