// Recurrence Type
export const RECURRENCE_TYPES = {
    DAILY: "daily",
    WEEKLY: "weekly",
    BI_WEEKLY: "bi_weekly",
    MONTHLY: "monthly",
    QUARTERLY: "quarterly",
    BI_ANNUALLY: "bi_annually",
    ANNUALLY: "annually",
};
export const RECURRENCE_TYPES_MAP = {
    daily: "Daily",
    weekly: "Weekly",
    bi_weekly: "Bi-weekly",
    monthly: "Monthly",
    quarterly: "Quarterly",
    bi_annually: "Bi-annually",
    annually: "Annually",
};
export const RECURRENCE_TYPES_LIST = Object.keys(RECURRENCE_TYPES_MAP).map(
    (key) => {
        return {
            label: RECURRENCE_TYPES_MAP[key],
            value: key,
        };
    }
);
export const DATE_FORMATS = {
    MDY: "MM/DD/YYYY",
    DMY: "DD/MM/YYYY",
    YMD: "YYYY/MM/DD",
    _MDY: "MM-DD-YYYY",
    _DMY: "DD-MM-YYYY",
    _YMD: "YYYY-MM-DD",
};
export const DATE_FORMATS_LIST = () => {
    return Object.keys(DATE_FORMATS).map((key) => {
        return {
            label: DATE_FORMATS[key],
            value: DATE_FORMATS[key],
        };
    });
};
