import { $notify, getMessageFromError } from "@/plugins/Helpers";

export default {
    namespaced: true,
    state: {
        entities: [],
        types: [],
        data: {},
        settings: {},
    },

    actions: {
        // settings
        fetchSettings: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/partnerships/setting/`
                )
                .then((res) => {
                    commit("setSettings", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        updateSettings: ({ rootState, commit }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/partnerships/setting/update/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Settings updated successfully", "UPDATE");
                    commit("setSettings", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // Entity
        fetchEntities: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/partnerships/partners-entity/all/?page=1&page_size=100`
                )
                .then((res) => {
                    commit("setEntities", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        createEntity: ({ rootState, commit }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/partnerships/partners-entity/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success(
                        "Partnerships entity created successfully",
                        "CREATE"
                    );
                    commit("setEntity", res.data);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        updateEntity: ({ rootState, commit }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/partnerships/partners-entity/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    $notify.success(
                        "Partnerships entity updated successfully",
                        "UPDATE"
                    );
                    commit("updateEntity", res.data);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        deleteEntity: ({ rootState }, id) => {
            return axios
                .delete(
                    `/${rootState.auth.organization.organization}/partnerships/partners-entity/delete/${id}/`
                )
                .then((res) => {
                    $notify.success(
                        "Partnerships entity deleted successfully",
                        "DELETE"
                    );
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // Types
        fetchTypes: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/partnerships/partners-type/all/?page=1&page_size=100`
                )
                .then((res) => {
                    commit("setTypes", res.results);
                    return res.results;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        createType: ({ rootState, commit }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/partnerships/partners-type/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success(
                        "Partnerships type created successfully",
                        "CREATE"
                    );
                    commit("setType", res.data);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        updateType: ({ rootState, commit }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/partnerships/partners-type/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    $notify.success(
                        "Partnerships type updated successfully",
                        "UPDATE"
                    );
                    commit("updateType", res.data);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        deleteType: ({ rootState }, id) => {
            return axios
                .delete(
                    `/${rootState.auth.organization.organization}/partnerships/partners-type/delete/${id}/`
                )
                .then((res) => {
                    $notify.success(
                        "Partnerships type deleted successfully",
                        "DELETE"
                    );
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setEntities: (state, payload) => {
            const data = payload.map((item) => {
                return {
                    id: item.id,
                    name: item.partner_entity.name,
                    is_default: item.partner_entity.is_default,
                    is_checked: item.is_checked,
                    label: item.partner_entity.name,
                    value: item.partner_entity.id,
                };
            });
            state.entities = data;
        },
        clearEntities: (state) => {
            state.entities = [];
        },
        setEntity: (state, payload) => {
            const data = {
                id: payload.id,
                name: payload.partner_entity.name,
                is_default: payload.partner_entity.is_default,
                is_checked: payload.is_checked,
                label: payload.partner_entity.name,
                value: payload.partner_entity.id,
            };
            state.entities.push(data);
        },
        updateEntity: (state, payload) => {
            const data = {
                id: payload.id,
                name: payload.partner_entity.name,
                is_default: payload.partner_entity.is_default,
                is_checked: payload.is_checked,
                label: payload.partner_entity.name,
                value: payload.partner_entity.id,
            };
            const currentData = state.entities.find(
                (item) => item.id === data.id
            );
            state.entities[state.entities.indexOf(currentData)] = data;
        },
        setTypes: (state, payload) => {
            const data = payload.map((item) => {
                return {
                    id: item.id,
                    name: item.partnership_type.name,
                    is_default: item.partnership_type.is_default,
                    is_checked: item.is_checked,
                    label: item.partnership_type.name,
                    value: item.partnership_type.id,
                };
            });
            state.types = data;
        },
        clearTypes: (state) => {
            state.types = [];
        },
        setType: (state, payload) => {
            const data = {
                id: payload.id,
                name: payload.partnership_type.name,
                is_default: payload.partnership_type.is_default,
                is_checked: payload.is_checked,
                label: payload.partnership_type.name,
                value: payload.partnership_type.id,
            };
            state.types.push(data);
        },
        updateType: (state, payload) => {
            const data = {
                id: payload.id,
                name: payload.partnership_type.name,
                is_default: payload.partnership_type.is_default,
                is_checked: payload.is_checked,
                label: payload.partnership_type.name,
                value: payload.partnership_type.id,
            };
            const currentData = state.types.find((item) => item.id === data.id);
            state.types[state.types.indexOf(currentData)] = data;
        },
        setSettings: (state, payload) => {
            payload.partnership_fields_mapping = JSON.parse(
                payload.partnership_fields_mapping
            );
            state.settings = payload;

            let data = {
                regarding: {},
                deliverable: {},
                contacts: {},
                others_info: {},
                other_fields: {},
            };

            const { partnership_fields_mapping, ...others } = payload;

            let {
                other_information,
                key_point_of_contact,
                regarding_partners,
            } = partnership_fields_mapping;

            data.regarding = regarding_partners;

            // contacts
            data.contacts = Object.keys(key_point_of_contact)
                .filter((key) => key_point_of_contact[key].is_checked)
                .reduce((obj, key) => {
                    return Object.assign(obj, {
                        [key]: key_point_of_contact[key],
                    });
                }, {});

            // other information
            data.deliverable = other_information.partner_deliverables;
            data.others_info = Object.keys(other_information)
                .filter(
                    (key) =>
                        other_information[key].default &&
                        other_information[key].is_checked &&
                        key !== "partner_deliverables"
                )
                .reduce((obj, key) => {
                    return Object.assign(obj, {
                        [key]: other_information[key],
                    });
                }, {});
            data.other_fields = Object.keys(other_information)
                .filter(
                    (key) =>
                        !other_information[key].default &&
                        other_information[key].is_checked &&
                        key !== "partner_deliverables"
                )
                .reduce((obj, key) => {
                    return Object.assign(obj, {
                        [key]: other_information[key],
                    });
                }, {});

            data = { ...data, ...others };

            state.data = data;

            // state.data = {
            //     ...others,
            //     other_information: other_information,
            //     key_point_of_contact: key_point_of_contact,
            //     regarding_partners: regarding_partners,
            //     partner_deliverables: partner_deliverables,
            //     other_fields_mapping: other_fields_mapping,
            // };
        },

        reset: (state) => {
            state.entities = [];
            state.types = [];
            state.data = {};
            state.settings = {};
        },
    },
};
