import { getMessageFromError } from "@/plugins/Helpers";

export default {
    state: {
        data: {},
    },
    mutations: {
        reset: (state) => {
            state.data = {};
        },
    },
    actions: {
        checkout: ({ rootState }, { type = "", seats = 0 }) => {
            const payload = {
                num_of_users: seats,
                success_url: `${location.href}?callback=${type}`,
            };
            return axios
                .post(
                    `/billings/checkout/${rootState.auth.organization.organization}/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        updateSubscription: ({ rootState }, seats = 0) => {
            const payload = {
                num_of_users: seats,
                subscription_id:
                    rootState.auth.organization.stripe_subscription_id,
            };
            return axios
                .put(
                    `/billings/update/subscription/${rootState.auth.organization.organization}/`,
                    payload
                )
                .then((res) => {
                    return res;
                });
        },
    },
};
