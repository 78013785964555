const defaultParams = {
    page: 1,
    page_size: 10,
};
export default {
    state: {
        partners: [],
        partner: {},
    },

    actions: {
        fetch: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/program_activities/program/partner/all/?page=${params.page}&page_size=${params.page_size}`
                )
                .then((res) => {
                    commit("setPartners", res.results);
                    return res;
                });
        },
        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/program_activities/program/partner/create/`,
                    payload
                )
                .then((res) => {
                    return res;
                });
        },
        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/program_activities/program/partner/edit/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    return res;
                });
        },
    },
    mutations: {
        setPartners: (state, payload) => {
            state.partners = payload;
        },
        clearPartners: (state) => {
            state.partners = [];
        },
        setPartner: (state, payload) => {
            state.partner = payload;
        },
        clearPartner: (state) => {
            state.partner = {};
        },
        reset: (state) => {
            state.partners = [];
            state.partner = {};
        },
    },
};
