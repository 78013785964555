import { Auth } from "@/middleware/auth";
import { modules } from "@/enum/UserPermissions";

const breadcrumbs = [
    {
        label: "Dashboard",
        name: "dashboard",
        active: false,
    },
    {
        label: "People",
        name: "people",
        active: false,
    },
];
export default [
    {
        path: "/people",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        beforeEnter: Auth,
        meta: {
            permission: modules.root_people,
            title: "People",
            submenu: [
                {
                    label: "Insight",
                    name: "people-insight",
                    active: true,
                    permission: modules.people_insight,
                },
                {
                    label: "Teams",
                    name: "people-teams",
                    active: true,
                    permission: modules.people_teams,
                },
                {
                    label: "Documents",
                    name: "people-documents",
                    active: process.env.MODE !== "production",
                    permission: modules.people_documents,
                },
                {
                    label: "Settings",
                    name: "people-settings",
                    active: true,
                    permission: modules.people_settings,
                },
            ],
        },

        children: [
            {
                path: "",
                name: "people",
                redirect: () => {
                    return { name: "people-insight" };
                },
            },
            {
                path: "/people/insight",
                name: "people-insight",
                beforeEnter: Auth,
                meta: {
                    permission: modules.people_insight,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Insight",
                            name: "people-insight",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/people/insight/index.vue"
                    ),
            },
            {
                path: "/people/teams",
                name: "people-teams",
                beforeEnter: Auth,
                meta: {
                    permission: modules.people_teams,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Teams",
                            name: "people-teams",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/people/teams/index.vue"
                    ),
            },
            {
                path: "/people/documents",
                name: "people-documents",
                beforeEnter: Auth,
                meta: {
                    permission: modules.people_documents,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Documents",
                            name: "people-documents",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/people/documents/index.vue"
                    ),
            },
            {
                path: "/people/settings",
                name: "people-settings",
                beforeEnter: Auth,
                meta: {
                    permission: modules.people_settings,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Settings",
                            name: "people-settings",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/people/settings/index.vue"
                    ),
            },
        ],
    },
];
