import { $notify, getMessageFromError, $setParams } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
export default {
    state: {
        boardMember: [],
        individuals: [],
        individual: {},
    },
    actions: {
        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `${rootState.auth.organization.organization}/fundraising/individuals/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Individuals created successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetch: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `${
                        rootState.auth.organization.organization
                    }/fundraising/individuals/all/${$setParams(params)}`
                )
                .then((res) => {
                    commit("setIndividuals", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `${rootState.auth.organization.organization}/fundraising/individuals/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Individuals updated successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchBoardMember: ({ rootState, commit }) => {
            return axios
                .get(
                    `${rootState.auth.organization.organization}/fundraising/people-relation/?type=4`
                )
                .then((res) => {
                    commit("setBoardMember", res.results);
                    return res.results;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        downloadIndividual: ({ rootState })=>{
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/fundraising/individuals/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadIndividualFile: ({ rootState },payload)=>{
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/fundraising/individuals/upload/`,payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setBoardMember: (state, payload) => {
            state.boardMember = payload;
        },
        setIndividuals: (state, payload) => {
            state.individuals = payload;
        },
        setIndividual: (state, payload) => {
            state.individual = payload;
        },
        clearIndividual: (state) => {
            state.individual = {};
        },
        reset: (state) => {
            state.boardMember = [];
            state.individuals = [];
            state.individual = {};
        },
    },
};
