import axios from "axios";

export default {
    state: {
        colors: null,
        loading: false,
    },
    actions: {
        setChartColors: ({ rootState, commit }, payload) => {
            return axios.post(`/${rootState.auth.organization.organization}/color/insight/`, payload)
                .then((res) => {
                    commit("setColors", res)
                })
            // if(payload.code_2!=""){
            // }
        }
    },
    mutations: {
        setColors: (state, data) => {
            state.colors = data;
        },
        setLoading: (state, data) => {
            state.loading = data;
        },
        reset: (state) => {
            state.colors = null;
            state.loading = false;
        },
    },
};
