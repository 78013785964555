import { $notify, getMessageFromError } from "@/plugins/Helpers";

export default {
    namespaced: true,
    state: {
        data: {},
        peoples: [],
    },

    actions: {
        fetch: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/finances/settings/retrieve/`
                )
                .then((res) => {
                    commit("setData", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/finances/settings/update/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Finance settings updated successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchAuthorityPeoples: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/finances/settings/authority-people/`
                )
                .then((res) => {
                    commit("setPeoples", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setData: (state, payload) => {
            state.data = payload;
        },
        setPeoples: (state, payload) => {
            state.peoples = payload;
        },
        reset: (state) => {
            state.data = {};
        },
    },
};
