export default {
    namespaced: true,
    state: {
        employments: [],
        team_type: [],
        race_type: [],
        location: [],
    },
    actions: {
        fetchEmploymentRoles: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/peoples/chart/employment/type/report/`
                )
                .then((res) => {
                    commit("setEmployments", res);
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchTeamType: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/peoples/chart/role/report/`
                )
                .then((res) => {
                    commit("setTeamType", res);
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchRaceType: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/peoples/chart/employee/using/report/`
                )
                .then((res) => {
                    commit("setRaceType", res);
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchLocation: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/peoples/chart/employee/location/report/`
                )
                .then((res) => {
                    commit("setLocation", res);
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },

    mutations: {
        setEmployments: (state, payload) => {
            state.employments = payload;
        },
        setTeamType: (state, payload) => {
            state.team_type = payload;
        },
        setRaceType: (state, payload) => {
            state.race_type = payload;
        },
        setLocation: (state, payload) => {
            state.location = payload;
        },
        reset: (state) => {
            state.employments = [];
            state.team_type = [];
            state.race_type = [];
            state.location = [];
        },
    },
};
