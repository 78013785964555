import { $notify, getMessageFromError } from "@/plugins/Helpers";

export default {
    namespaced: true,
    state: {
        userInfo: {},
    },
    actions: {
        updateProfile: ({ rootState }, payload) => {
            payload.id = rootState.auth.user?.id;
            return axios
                .put(`/user/update/${payload.id}/`, payload)
                .then((res) => {
                    $notify.success("Profile update successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchUserProfile: ({ rootState }) => {
            let user_id = rootState.auth.user?.id;
            return axios
                .get(`/user/${user_id}/`)
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        reset: (state) => {
            state.userInfo = {};
        },
    },
};
