// import { Auth } from "@/middleware/auth";
import { modules } from "@/enum/UserPermissions";

const breadcrumbs = [
    {
        label: "Dashboard",
        name: "dashboard",
        active: false,
    },
    {
        label: "Fundraising",
        name: "fundraising",
        active: false,
    },
];

export default [
    {
        path: "/fundraising",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        // beforeEnter: Auth,
        meta: {
            title: "Fundraising",
            submenu: [
                {
                    label: "Insight",
                    name: "fundraising-insight",
                    active: true,
                    permission: modules.fundraising_insight,
                },
                {
                    label: "Foundations",
                    name: "fundraising-foundations",
                    active: true,
                    permission: modules.fundraising_foundations,
                },
                {
                    label: "Corporation",
                    name: "fundraising-corporation",
                    active: true,
                    permission: modules.fundraising_corporations,
                },
                {
                    label: "Government",
                    name: "fundraising-government",
                    active: true,
                    permission: modules.fundraising_governments,
                },
                {
                    label: "Individuals",
                    name: "fundraising-individuals",
                    active: true,
                    permission: modules.fundraising_individuals,
                },
                {
                    label: "Documents",
                    name: "fundraising-documents",
                    active: process.env.MODE !== "production",
                    permission: modules.fundraising_documents,
                },
                {
                    label: "Settings",
                    name: "fundraising-settings",
                    active: true,
                    permission: modules.fundraising_settings,
                },
            ],
        },
        children: [
            {
                path: "",
                name: "fundraising",
                redirect: () => {
                    return { name: "fundraising-insight" };
                },
            },
            {
                path: "/fundraising/insight",
                name: "fundraising-insight",
                // beforeEnter: Auth,
                meta: {
                    permission: modules.fundraising_insight,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Insight",
                            name: "fundraising-insight",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/fundraising/insight/index.vue"
                    ),
            },
            {
                path: "/fundraising/foundations",
                name: "fundraising-foundations",
                // beforeEnter: Auth,
                meta: {
                    permission: modules.fundraising_foundations,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Foundations",
                            name: "fundraising-foundations",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/fundraising/foundations/index.vue"
                    ),
            },
            {
                path: "/fundraising/corporation",
                name: "fundraising-corporation",
                // beforeEnter: Auth,
                meta: {
                    permission: modules.fundraising_corporations,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Corporation",
                            name: "fundraising-corporation",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/fundraising/corporation/index.vue"
                    ),
            },
            {
                path: "/fundraising/government",
                name: "fundraising-government",
                // beforeEnter: Auth,
                meta: {
                    permission: modules.fundraising_governments,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Government",
                            name: "fundraising-government",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/fundraising/government/index.vue"
                    ),
            },
            {
                path: "/fundraising/individuals",
                name: "fundraising-individuals",
                // beforeEnter: Auth,
                meta: {
                    permission: modules.fundraising_individuals,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Individuals",
                            name: "fundraising-individuals",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/fundraising/individuals/index.vue"
                    ),
            },
            {
                path: "/fundraising/documents",
                name: "fundraising-documents",
                // beforeEnter: Auth,
                meta: {
                    permission: modules.fundraising_documents,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Documents",
                            name: "fundraising-documents",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/fundraising/documents/index.vue"
                    ),
            },
            {
                path: "/fundraising/settings",
                name: "fundraising-settings",
                // beforeEnter: Auth,
                meta: {
                    permission: modules.fundraising_settings,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Settings",
                            name: "fundraising-settings",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/fundraising/settings/index.vue"
                    ),
            },
        ],
    },
];
