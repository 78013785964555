import { getMessageFromError } from "@/plugins/Helpers";

export default {
    state: {
        composition: {},
        totolFunding: {},
        fundingTrend: {},
        fundraisingStatus: {},
    },
    actions: {
        fetchComposition: ({ rootState, commit }) => {
            return axios
                .get(
                    `${rootState.auth.organization.organization}/fundraising/chart/funding/composition/`
                )
                .then((res) => {
                    commit("setComposition", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchTotalFunding: ({ rootState, commit }) => {
            return axios
                .get(
                    `${rootState.auth.organization.organization}/fundraising/chart/total/funding/`
                )
                .then((res) => {
                    commit("setTotalFunding", res);
                    return res;
                });
        },
        fetchFundingTrend: ({ rootState, commit }) => {
            return axios
                .get(
                    `${rootState.auth.organization.organization}/fundraising/chart/funding/trend/`
                )
                .then((res) => {
                    commit("setFundingTrend", res);
                    return res;
                });
        },
        fetchStatus: ({ rootState, commit }) => {
            return axios
                .get(
                    `${rootState.auth.organization.organization}/fundraising/chart/funding/status/`
                )
                .then((res) => {
                    commit("setStatus", res);
                    return res;
                });
        },
    },
    mutations: {
        setComposition: (state, payload) => {
            state.composition = payload;
        },
        setTotalFunding: (state, payload) => {
            state.totolFunding = payload;
        },
        setFundingTrend: (state, payload) => {
            state.fundingTrend = payload;
        },
        setStatus: (state, payload) => {
            state.fundraisingStatus = payload;
        },
        reset: (state) => {
            state.composition = {};
            state.totolFunding = {};
            state.fundingTrend = {};
            state.fundraisingStatus = {};
        },
    },
};
