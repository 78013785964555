export default {
    namespaced: true,
    state: {
        data: {},
    },

    actions: {
        fetchHandbook: ({ commit }) => {
            return axios.get("/organization/handbook/").then((res) => {
                commit("setData", res);
                return res;
            });
        },
    },
    mutations: {
        setData: (state, data) => {
            state.data = data;
        },
        reset: (state) => {
            state.data = {};
        },
    },
};
