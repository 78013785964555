import Swal from "sweetalert2";
import { notify } from "@kyvg/vue3-notification";
import { store } from "@/store";
import { authorityLevel, permissions } from "@/enum/UserPermissions";
import router from "@/router";
import moment from "moment";
import { DATE_FORMATS } from "@/enum/Common";

const customClass = {
    closeButton:
        "!bg-primary-1 !text-button-1 !h-8 !w-8 !rounded-2xl !text-2xl !mt-6 !mr-6",
    confirmButton:
        "!text-lg !font-semibold !outline-none !shadow-none !bg-primary-1 !text-button-1 hover:!bg-primary-1-500 !rounded-lg !h-[50px]",
    cancelButton:
        "!text-lg !font-semibold !bg-transparent !text-primary-2 hover:!bg-primary-2 hover:!text-white !rounded-lg !h-[50px]",
    popup: "!rounded-lg",
    icon: "!mt-5",
    timerProgressBar: "!bg-primary-1",
};

// old used helpers from store/helpers
export const confirmation = (item) => {
    return Swal.fire({
        title: item.title,
        text: item.text,
        showCancelButton: item.showCancelButton,
        confirmButtonText: item.confirmButtonText,
        showCloseButton: item.showCloseButton,
        reverseButtons: item.reverseButtons,
        customClass: {
            title: `${item.title} ? '!text-primary-2 !block !h-14' : ''`,
            closeButton:
                "!bg-primary-1 !text-button-1 !h-8 !w-8 !rounded-2xl !text-2xl !mt-6 !mr-6",
            confirmButton:
                "!text-lg !font-semibold !outline-none !shadow-none !bg-primary-1 !text-button-1 hover:!bg-primary-1-500 !rounded-lg !h-[50px]",
            cancelButton:
                "!text-lg !font-semibold !bg-transparent !text-primary-2 hover:!bg-primary-2 hover:!text-white !rounded-lg !h-[50px]",
            popup: "!rounded-lg",
        },
    }).then((res) => {
        return res;
    });
};

export const getMessageFromError = async (error) => {
    const response = error.response;
    const data = error.response.data;

    if (response?.status === 401 && store.state.auth.isLoggedIn) {
        await store.dispatch("auth/logout").then(() => {
            return router.push({ name: "login" });
        });
    }
    if (data?.details) {
        return notify({
            title: "Error",
            text: error.response.data.details,
            type: "error",
            duration: 10000,
        });
    }
    if (data?.detail) {
        return notify({
            title: "Error",
            text: error.response.data.detail,
            type: "error",
            duration: 10000,
        });
    }
    data.map((message) => {
        return notify({
            title: "Error",
            text: message,
            type: "error",
            duration: 10000,
        });
    });

    return response;
};

export const requestFullScreen = () => {
    if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
    } else if (document.documentElement.mozRequestFullScreen) {
        /* Firefox */
        document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullScreen) {
        /* Chrome, Safari & Opera */
        document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
        );
    } else if (document.msRequestFullscreen) {
        /* IE/Edge */
        document.documentElement.msRequestFullscreen();
    }
};
export const cancelFullScreen = () => {
    if (document.cancelFullScreen) {
        document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
        /* Chrome, Safari and Opera */
        document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
    }
};
export const toggleFullscreen = () => {
    if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
        requestFullScreen();
    } else {
        cancelFullScreen();
    }
};

export const $notify = {
    success: (text, title = "Success", duration = 5000, speed = 300) => {
        notify({
            title: title,
            text: text,
            type: "success",
            duration: duration,
            speed: speed,
        });
    },
    warn: (text, title = "Warning", duration = 5000, speed = 300) => {
        notify({
            title: title,
            text: text,
            type: "warn",
            duration: duration,
            speed: speed,
        });
    },
    error: (text, title = "Error", duration = 5000, speed = 300) => {
        notify({
            title: title,
            text: text,
            type: "error",
            duration: duration,
            speed: speed,
        });
    },
};

export const $alert = {
    success: (options = {}) => {
        const defaultOptions = {
            icon: false,
            confirmButtonText: "Ok",
            customClass: { ...customClass, title: "!text-lime-700 !h-14" },
            ...options,
        };
        return Swal.fire(defaultOptions);
    },
    warn: (options = {}) => {
        const defaultOptions = {
            icon: false,
            confirmButtonText: "Ok",
            customClass: { ...customClass, title: "!text-primary-1 !h-14" },
            ...options,
        };
        return Swal.fire(defaultOptions);
    },
    error: (options = {}) => {
        const defaultOptions = {
            icon: false,
            confirmButtonText: "Ok",
            ...options,
        };
        if (options.customClass) {
            defaultOptions.customClass = {
                ...customClass,
                title: "!text-primary-2 !h-14",
                ...options.customClass,
            };
        } else {
            defaultOptions.customClass = {
                ...customClass,
                title: "!text-primary-2 !h-14",
            };
        }

        return Swal.fire(defaultOptions);
    },
    confirm: (options = {}, extraClasses = {}) => {
        const defaultOptions = {
            title: "Are you sure?",
            text: "You want to do this!",
            icon: false,
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                ...customClass,
                title: "!text-primary-1 !h-14",
                ...extraClasses,
            },
            ...options,
        };
        return Swal.fire(defaultOptions);
    },
};

export const $hasPermission = {
    create: (module) => {
        if (!store.state.auth.permissions || !module) return false;
        return !!store.state.auth.permissions[module].includes(
            permissions.CREATE
        );
    },
    view: (module) => {
        if (!store.state.auth.permissions || !module) return false;
        return !!store.state.auth.permissions[module].includes(
            permissions.VIEW
        );
    },
    update: (module) => {
        if (!store.state.auth.permissions || !module) return false;
        return !!store.state.auth.permissions[module].includes(
            permissions.UPDATE
        );
    },
    delete: (module) => {
        if (!store.state.auth.permissions || !module) return false;
        return !!store.state.auth.permissions[module].includes(
            permissions.DELETE
        );
    },
    user: (module) => {
        if (!store.state.auth.permissions || !module) return false;
        return !!store.state.auth.permissions[module].includes(
            permissions.USER
        );
    },
    manager: (module) => {
        if (!store.state.auth.permissions || !module) return false;
        return !!store.state.auth.permissions[module].includes(
            authorityLevel.MANAGER
        );
    },
    contributor: (module) => {
        if (!store.state.auth.permissions || !module) return false;
        return !!store.state.auth.permissions[module].includes(
            authorityLevel.CONTRIBUTOR
        );
    },
    contentManager: (module) => {
        if (!store.state.auth.permissions || !module) return false;
        return !!store.state.auth.permissions[module].includes(
            authorityLevel.CONTENT_MANAGER
        );
    },
    viewer: (module) => {
        if (!store.state.auth.permissions || !module) return false;
        return !!store.state.auth.permissions[module].includes(
            authorityLevel.VIEWER
        );
    },
    any: (module) => {
        if (module === "all") {
            return true;
        }
        if (
            !store.state.auth.permissions ||
            !module ||
            !store.state.auth.permissions[module]
        )
            return false;
        return !store.state.auth.permissions[module].includes("N");
    },
};

export const $setParams = (params = {}) => {
    let url = "?";
    console.log("params", params);
    Object.keys(params).map((key, i) => {
        url += `${key}=${
            // (key === "page" && params.query) ||
            //key === "page" && params.page >= 1 ? "1" : params[key]

            setQuery(params, key)
        }`;
        if (i + 1 !== Object.keys(params).length) {
            url += "&";
        }
    });
    return url;
};
const setQuery = (params, key) => {
    console.log("key", key);
    let Page = params[key];
    if (key === "page") {
        if (!params.query && params.query !== "" && params.page >= 1) {
            Page = 1;
        } else if (!params && params.page >= 1) {
            Page = params[key];
        } else {
            if (params.query && params.page >= 1 && !params.next) {
                Page = 1;
            }
            if (params.query && params.page >= 1 && params.next) {
                Page = params[key];
            }
        }
    } else {
        Page = params[key];
        if (key === "next") {
            Page = null;
        }
    }
    return Page;
};

export const $getFormattedDate = (date, format = $orgDateFormat()) => {
    if (date) {
        return moment(date).format(format);
    }
    return "";
};

export const $getFormattedAmount = (
    amount,
    currency = $orgCurrency({ type: "symbol" })
) => {
    if (amount) {
        amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${currency}${amount}`;
    }
    return "";
};

export const $orgDateFormat = (
    organization = store.state.auth?.organization
) => {
    if (organization && organization.date_format) {
        return organization.date_format;
    }
    return DATE_FORMATS.MDY;
};

export const $orgCurrency = ({
    organization = store.state.auth?.organization,
    type = null,
    currency = "$",
}) => {
    if (!organization || !organization.currency) return currency;
    if (type === "name") {
        return organization.currency.name;
    }
    if (type === "symbol") {
        return organization.currency.symbol;
    }
    return `${organization.currency.symbol} ${organization.currency.name}`;
};

export const $orgTimezone = (
    organization = store.state.auth?.organization,
    type = null
) => {
    if (!organization || !organization.timezone) return "";

    if (type === "value") {
        return organization.timezone.value;
    }
    // if (type === "text") {
    //     return organization.timezone.text;
    // }
    return organization.timezone.text;
};

export const $defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
