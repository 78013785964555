import { Auth } from "@/middleware/auth";
import { modules } from "@/enum/UserPermissions";

const breadcrumbs = [
    {
        label: "Dashboard",
        name: "dashboard",
        active: false,
    },
    {
        label: "Settings",
        name: "settings",
        active: false,
    },
];

export default [
    {
        path: "/settings",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        beforeEnter: Auth,
        meta: {
            permission: modules.root_settings,
            title: "Settings",
            submenu: [
                {
                    label: "Insight",
                    name: "insight",
                    active: true,
                    permission: modules.settings_user_and_permissions,
                },
                {
                    label: "Users & Permissions",
                    name: "users-permissions",
                    active: true,
                    permission: modules.settings_user_and_permissions,
                },
                {
                    label: "Security",
                    name: "settings-security",
                    active: true,
                    permission: modules.settings_security,
                },
                {
                    label: "Billing",
                    name: "settings-billing",
                    active: true,
                    permission: modules.settings_billing,
                },
                {
                    label: "Sign in",
                    name: "settings-signin",
                    active: true,
                    permission: modules.settings_signin,
                },
                {
                    label: "General Settings",
                    name: "general-settings",
                    active: true,
                    permission: modules.settings_signin,
                },
            ],
        },
        children: [
            {
                path: "",
                name: "settings",
                redirect: () => {
                    return { name: "insight" };
                },
            },
            {
                path: "/settings/insight",
                name: "insight",
                beforeEnter: Auth,
                meta: {
                    permission: modules.settings_user_and_permissions,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Insight",
                            name: "insight",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/settings/insight/index.vue"
                    ),
            },
            {
                path: "/settings/users-permissions",
                name: "users-permissions",
                beforeEnter: Auth,
                meta: {
                    permission: modules.settings_user_and_permissions,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Users & Permissions",
                            name: "users-permissions",
                            active: false,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/settings/users-permissions/index.vue"
                    ),
            },
            {
                path: "/settings/security",
                name: "settings-security",
                beforeEnter: Auth,
                meta: {
                    permission: modules.settings_security,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Security",
                            name: "settings-security",
                            active: false,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/settings/security/index.vue"
                    ),
            },
            {
                path: "/settings/billing",
                name: "settings-billing",
                beforeEnter: Auth,
                meta: {
                    permission: modules.settings_billing,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Billing",
                            name: "settings-billing",
                            active: false,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/settings/billing/index.vue"
                    ),
            },
            {
                path: "/settings/signin",
                name: "settings-signin",
                beforeEnter: Auth,
                meta: {
                    permission: modules.settings_signin,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Sign in",
                            name: "settings-signin",
                            active: false,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/settings/signin/index.vue"
                    ),
            },
            {
                path: "/settings/general-settings",
                name: "general-settings",
                beforeEnter: Auth,
                meta: {
                    permission: modules.settings_signin,
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "General Settings",
                            name: "general-settings",
                            active: false,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/dashboard/settings/general-settings/index.vue"
                    ),
            },
        ],
    },
];
