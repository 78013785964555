import { $alert, $setParams, getMessageFromError } from "@/plugins/Helpers";
import router from "@/router";
import EventHub from "@/plugins/EventHub";

export default {
    state: {
        auth: {},
        code: null,
        requestPath: null,
        user: null,
        module: null,
    },
    actions: {
        syncDropbox: ({ commit, dispatch, state }, module) => {
            commit("setModule", module);

            if (state.user) {
                dispatch("chooseFiles");
            } else {
                dispatch("getCode");
            }
        },
        getCode: () => {
            const params = {
                token_access_type: "offline",
                response_type: "code",
                client_id: process.env.DROPBOX_APP_KEY,
                client_secret: process.env.DROPBOX_APP_SECRET,
                redirect_uri: `${process.env.WEB_BASE_URL}/callback`,
                state: location.pathname,
            };
            location.href = `https://dropbox.com/oauth2/authorize${$setParams(
                params
            )}`;
        },

        getToken: ({ commit, dispatch }, data) => {
            commit("setCode", data.code);
            commit("setRequestPath", data.state);

            const params = {
                client_id: process.env.DROPBOX_APP_KEY,
                client_secret: process.env.DROPBOX_APP_SECRET,
                redirect_uri: `${process.env.WEB_BASE_URL}/callback`,
                code: data.code,
                grant_type: "authorization_code",
            };

            fetch(
                `https://api.dropboxapi.com/oauth2/token${$setParams(params)}`,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    commit("setAuth", data);
                    dispatch("getAccount", data);
                })
                .catch((err) => {
                    getMessageFromError(err);
                });
        },

        getAccount: ({ commit, state, dispatch }, data) => {
            fetch("https://api.dropboxapi.com/2/users/get_account", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${data.access_token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    account_id: data.account_id,
                }),
            })
                .then((res) => {
                    return res.json();
                })
                .then(async (data) => {
                    const userData = {
                        email: data.email,
                        sub: "",
                        name: data.name.display_name,
                        picture: "",
                        email_verified: data.email_verified,
                        hd: "",
                    };
                    commit("setUser", userData);
                    router.push(state.requestPath).then(() => {
                        $alert
                            .success({
                                icon: "success",
                                allowOutsideClick: false,
                                text: `Dropbox successfully synced with ${process.env.APP_NAME}. Please choose your documents.`,
                                showCancelButton: true,
                                reverseButtons: true,
                                confirmButtonText: "Choose",
                            })
                            .then((res) => {
                                if (res.isConfirmed) {
                                    try {
                                        dispatch("chooseFiles");
                                    } catch (e) {
                                        commit("reset");
                                        console.log(
                                            "dropbox choose files error",
                                            e
                                        );
                                    }
                                }
                            });
                    });
                })
                .catch((err) => {
                    getMessageFromError(err);
                });
        },

        chooseFiles: ({ dispatch, commit }) => {
            let options = {
                success: async (files) => {
                    return dispatch("pickerCallback", files);
                },

                cancel: () => {
                    commit("reset");
                },

                linkType: "preview",
                multiselect: true,
                // extensions: [
                //     ".png",
                //     ".jpeg",
                //     ".gif",
                //     ".jpg",
                //     ".svg",
                //     ".webp",
                //     ".ico",
                //     ".mp3",
                //     ".aac",
                //     ".3gp",
                //     ".mpeg",
                //     ".mpg",
                //     ".webm",
                //     ".mp4",
                //     ".avi",
                //     ".mkv",
                //     ".pdf",
                //     ".doc",
                //     ".docx",
                // ],
                folderselect: false,
                sizeLimit: 102400000,
            };
            return window.Dropbox.choose(options);
        },

        pickerCallback: ({ state, dispatch, commit }, files) => {
            const getEmbedLink = (item) => {
                const types = [
                    ".png",
                    ".jpeg",
                    ".gif",
                    ".jpg",
                    ".svg",
                    ".webp",
                    ".ico",
                    ".mp3",
                    ".aac",
                    ".3gp",
                    ".mpeg",
                    ".mpg",
                    ".webm",
                    ".mp4",
                    ".avi",
                    ".mkv",
                    ".pdf",
                ];
                let link = null;
                types.map((type) => {
                    if (item.name.toLowerCase().includes(type)) {
                        link = item.link.replace("?dl=0", "?raw=1");
                    }
                });
                return link;
            };

            const documents = files.map((item) => {
                return {
                    document_name: item?.name,
                    document_link: item?.link,
                    embed_link: getEmbedLink(item),
                    document_id: item?.id,
                    doc_type: item?.linkType,
                    icon_url: item?.icon,
                };
            });
            const finalData = {
                drive_type: "dropbox",
                module_name: state.module,
                documents: documents,
                ...state.user,
            };
            dispatch("documents/create", finalData, { root: true }).then(() => {
                EventHub.$emit(`synced/${state.module}`);
                commit("reset");
            });
            return finalData;
        },
    },

    mutations: {
        setCode: (state, code) => {
            state.code = code;
        },
        setRequestPath: (state, path) => {
            state.requestPath = path;
        },
        setAuth: (state, data) => {
            state.auth = data;
        },
        setUser: (state, data) => {
            state.user = data;
        },
        setModule: (state, module) => {
            state.module = module;
        },

        reset: (state) => {
            state.auth = {};
            state.code = null;
            state.requestPath = null;
            state.user = null;
            state.module = null;
        },
    },
};
