import { getMessageFromError, $setParams } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
export default {
    state: {
        services: [],
        service: {},
    },
    actions: {
        fetchServices: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${
                        rootState.auth.organization.organization
                    }/services/all/${$setParams(params)}`
                )
                .then((res) => {
                    commit("setServices", res.results);
                    return res;
                });
        },

        createService: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/services/create/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                // .catch((error) => {
                //     getMessageFromError(error);
                // });
        },
        updateService: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/services/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        deleteService: ({ rootState }, id) => {
            return axios
                .delete(
                    `/${rootState.auth.organization.organization}/services/delete/${id}/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        getJson:({ rootState }) =>{
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/services/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadExcleFile:({ rootState },data)=>{
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/services/upload/`,data
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        }
    },
    mutations: {
        setServices: (state, payload) => {
            state.services = payload;
        },
        clearServices: (state) => {
            state.services = [];
        },
        setService: (state, payload) => {
            state.service = payload;
        },
        clearService: (state) => {
            state.service = {};
        },
        reset: (state) => {
            state.services = [];
            state.service = {};
        },
    },
};
