// Import necessary packages for boot application
import "@/assets/css/main.css";

// Import packages
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import Notifications from "@kyvg/vue3-notification";
import VueTheMask from "vue-the-mask";
import moment from "moment";
import plugins from "@/plugins";
import loadCustomMeta from "@/utils/loadCustomMeta";
import { initSessionTracker } from "@/plugins/sessionChecker";

require("@mdi/font/css/materialdesignicons.css");
require("@/plugins/Axios");
require("@/plugins/ExternalScripts");

if (process.env.MODE === "production") {
    require("@/plugins/GoogleTag");
}

loadCustomMeta({
    name: "google-site-verification",
    content: "-2xEOR1Y4soHGzyq6I2dGm21iALfMqLAsU6I2MKscEY",
});

const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://fc23b6d520a742758bf9da23df47624a@o1086968.ingest.sentry.io/6259281",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "app.quantibly.com", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
app.use(store)
    .use(plugins)
    .use(router)
    .use(Notifications)
    .use(moment)
    .use(VueTheMask)
    .use(initSessionTracker);
app.mount("#app");
