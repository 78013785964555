import axios from "axios"

export default {
    namespaced: true,
    state: { },
    actions:{
        gettingSupport:({rootState},payload)=>{
            // console.log(payload);
            return axios.post(`/${rootState.auth.organization.organization}/dashboard/support/create/`,payload)
            .then((res) => {
                return res;
            })
        }
    }
}