import axios from "axios";

export default {
    namespaced: true,
    state: {
        committeeAssignmentData: {},
        governanceTeamTypesData: {},
        raceEthnicity: {},
        expertise: {},
    },
    actions: {
        fetchGovernanceCommitteeAssignment: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/governance/chart/committee/assignment/`
                )
                .then((res) => {
                    commit("setCommitteeAssignment", res);
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchGovernanceTeamTypes: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/governance/chart/team/`
                )
                .then((res) => {
                    commit("setGovernanceTeamTypes", res);
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchRaceEthnicity: ({ rootState, commit }) => {
            return axios.get(`/${rootState.auth.organization.organization}/governance/chart/race/`)
                .then((res) => {
                    commit("setRaceEthnicity", res);
                    return res;
                })
        },
        fetchGovernanceMembers: ({ rootState, commit }) => {
            return axios.get(`/${rootState.auth.organization.organization}/governance/chart/user/governing/`)
                .then((res) => {
                    commit("setExpertise", res);
                    return res;
                })
        },

    },
    mutations: {
        setCommitteeAssignment: (state, payload) => {
            state.committeeAssignmentData = payload;
        },
        setGovernanceTeamTypes: (state, payload) => {
            state.governanceTeamTypesData = payload;
        },
        setRaceEthnicity: (state, payload) => {
            state.raceEthnicity = payload;
        },
        setExpertise: (state, payload) => {
            state.expertise = payload;
        },
    },
};
