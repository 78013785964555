import { Auth } from "@/middleware/auth";

export default [
    {
        path: "/welcome/",
        name: "welcome",
        beforeEnter: Auth,
        meta: {
            title: "Quantibly | Welcome",
        },
        component: () => import("@/views/auth/welcome.vue"),
    },
];
