export default function loadCustomScript(src, attributes = []) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", src);
        script.setAttribute("async", true);
        script.setAttribute("defer", true);

        if (attributes.length) {
            attributes.forEach((attribute) => {
                script.setAttribute(attribute.name, attribute.value);
            });
        }

        script.onload = (...args) => {
            resolve(...args);
        };

        script.onerror = (error) => {
            reject(error);
        };

        document.head.appendChild(script);
    });
}
