import { $setParams, getMessageFromError } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
export default {
    state: {
        types: [],
        stats: [],
        users: [],
        user: {},
    },
    actions: {
        fetchOrganizationTypes: ({ rootState, commit }) => {
            return axios
                .get(
                    `/organization/type/all/${rootState.auth.organization.organization}/`
                )
                .then((res) => {
                    res.results.map((item) => {
                        item.label = item.name;
                        item.value = item.id;
                    });
                    commit("setTypes", res.results);
                    return res.results;
                })
                .catch((err) => {
                    getMessageFromError(err);
                });
        },
        createOrganizationType: ({ rootState, commit }, payload) => {
            payload.organization = rootState.auth.organization.organization;
            return axios
                .post(
                    `/organization/type/create/${rootState.auth.organization.organization}/`,
                    payload
                )
                .then((res) => {
                    commit("setType", res);
                    return res;
                })
                .catch((err) => {
                    getMessageFromError(err);
                });
        },

        fetchStats: ({ rootState, commit }) => {
            return axios
                .get(
                    `/organization/profile/stats/${rootState.auth.organization.id}/`
                )
                .then((res) => {
                    commit("setStats", res);
                    return res;
                })
                .catch((err) => {
                    getMessageFromError(err);
                });
        },

        fetchUsers: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            const orgId = rootState.auth.organization.organization;
            return axios
                .get(`/organization/users/${orgId}/${$setParams(params)}`)
                .then(async (response) => {
                    await commit("setUsers", response.results);
                    return response;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        create: ({ rootState }, payload) => {
            payload.organization = rootState.auth.organization.organization;
            return axios
                .post(
                    `/organization/users/${payload.organization}/create/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        update: ({ rootState }, payload) => {
            payload.organization = rootState.auth.organization.organization;
            return axios
                .put(
                    `/organization/users/${payload.organization}/edit/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        sendInvitation: ({ rootState, state }, payload) => {
            payload.using_application = true;
            payload.people = state.user?.id;
            return axios
                .post(
                    `/organization/users/${rootState.auth.organization.organization}/invite/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchOrganizationUser: ({ rootState }, id) => {
            return axios
                .post(
                    `/organization/users/${rootState.auth.organization.organization}/retrieve/${id}/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        downloadSettingPermission: ({ rootState })=>{
            return axios
                .get(
                    `/organization/users/${rootState.auth.organization.organization}/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadSettingPermissionFile: ({ rootState },payload)=>{
            return axios
                .post(
                    `/organization/users/${rootState.auth.organization.organization}/upload/`,payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        }
    },
    mutations: {
        setTypes: (state, payload) => {
            state.types = payload;
        },
        setType: (state, payload) => {
            payload = {
                ...payload,
                ...{ label: payload.name, value: payload.id },
            };
            state.types.push(payload);
        },
        clearTypes: (state) => {
            state.types = [];
        },
        setStats: (state, payload) => {
            state.stats = payload;
        },
        clearStats: (state) => {
            state.stats = [];
        },
        setUsers: (state, payload) => {
            state.users = payload;
        },
        clearUsers: (state) => {
            state.users = [];
        },
        setUser: (state, payload) => {
            state.user = payload;
        },
        clearUser: (state) => {
            state.user = {};
        },
        reset: (state) => {
            state.types = [];
            state.stats = [];
            state.users = [];
            state.user = {};
        },
    },
};
