import { Auth } from "@/middleware/auth";

const breadcrumbs = [
    // {
    //     label: "Account",
    //     name: "#",
    //     active: false,
    // },
];
export default [
    {
        path: "/account",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/layouts/Dashboard"),
        beforeEnter: Auth,
        meta: {
            permission: "all",
            title: "Account Settings",
            submenu: [],
        },
        children: [
            {
                path: "",
                name: "account",
                redirect: () => {
                    return { name: "account-settings" };
                },
            },
            {
                path: "/account/account-settings",
                name: "account-settings",
                beforeEnter: Auth,
                meta: {
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "Account Settings",
                            name: "account-settings",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/account/account-settings/index.vue"
                    ),
            },
            {
                path: "/profile",
                name: "my-profile",
                beforeEnter: Auth,
                meta: {
                    breadcrumbs: [
                        ...breadcrumbs,
                        {
                            label: "My Profile",
                            name: "my-profile",
                            active: true,
                        },
                    ],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/account/my-profile/index.vue"
                    ),
            },
        ],
    },
];
