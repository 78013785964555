import { $notify, $setParams, getMessageFromError } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};

export default {
    state: {
        corporations: [],
        corporation: {},
    },
    actions: {
        fetch: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `${
                        rootState.auth.organization.organization
                    }/fundraising/corporations/all/${$setParams(params)}`
                )
                .then((res) => {
                    commit("setCorporations", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/fundraising/corporations/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Corporation created successfully");
                    return res;
                });
        },
        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/fundraising/corporations/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Corporation updated successfully");
                    return res;
                });
        },
        downloadCorporation: ({ rootState })=>{
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/fundraising/corporations/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadCorporationFile: ({ rootState },payload)=>{
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/fundraising/corporations/upload/`,payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setCorporations: (state, payload) => {
            state.corporations = payload;
        },
        setCorporation: (state, payload) => {
            state.corporation = payload;
        },
        clearCorporation: (state) => {
            state.corporation = {};
        },
        reset: (state) => {
            state.corporations = [];
            state.corporation = {};
        },
    },
};
