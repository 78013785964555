import { $setParams, $notify, getMessageFromError } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
export default {
    state: {
        expense: {},
        authorizePeoples: [],
        expensePeoples: [],
        expenseCategory: [],
        programs: [],
    },
    actions: {
        fetchAuthorizedPeople: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/finances/settings/authority-people/?type=1`
                )
                .then((res) => {
                    commit("setAuthorizePeoples", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchExpensePeople: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/finances/settings/authority-people/?type=2`
                )
                .then((res) => {
                    commit("setExpensePeoples", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchExpenseCategory: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/finances/settings/expense/line-item/all/??page=${params.page}&page_size=${params.page_size}&is_checked=${params.is_checked}`
                )
                .then((res) => {
                    commit("setExpenseCategory", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/finances/expenses/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Expenses created successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/finances/expenses/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Expenses updated successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        fetchExpenses: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${
                        rootState.auth.organization.organization
                    }/finances/expenses/all/${$setParams(params)}`
                )
                .then(async (response) => {
                    await commit("setExpense", response.results);
                    return response;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        downloadExpense: ({ rootState })=>{
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/finances/expenses/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadExpenseFile: ({ rootState },payload)=>{
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/finances/expenses/upload/`,payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        }
    },
    mutations: {
        setExpense: (state, data) => {
            state.expense = data;
        },
        clearExpense: (state) => {
            state.expense = {};
        },
        setAuthorizePeoples: (state, data) => {
            state.authorizePeoples = data;
        },
        setExpensePeoples: (state, data) => {
            state.expensePeoples = data;
        },
        setExpenseCategory: (state, data) => {
            state.expenseCategory = data;
        },
        setPrograms: (state, data) => {
            state.programs = data;
        },
        clearTeams: (state) => {
            state.teams = [];
        },
        setTeam: (state, data) => {
            state.team = data;
        },
        clearTeam: (state) => {
            state.team = {};
        },
        reset: (state) => {
            state.expense = {};
            state.authorizePeoples = [];
            state.expensePeoples = [];
            state.expenseCategory = [];
            state.programs = [];
        },
    },
};
