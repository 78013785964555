// for store all temporary data
export default {
    state: {
        data: null,
    },
    mutations: {
        setData: (state, data) => {
            state.data = data;
        },
        clearData: (state) => {
            state.data = null;
        },
        reset: (state) => {
            state.data = null;
        },
    },
};
