import { getMessageFromError } from "@/plugins/Helpers";

export default {
    namespaced: true,
    state: {
        stats: [],
    },
    actions: {
        // stats
        fetchStats: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/dashboard/portlets/`
                )
                .then((res) => {
                    commit("setStats", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchOnBording: ({ rootState }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/dashboard/onboarding-status/`
                )
                .then((res) => {
                    // console.log(res);
                    // commit("setStats", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // DASHBOARD PAGE
        fetchReminders: ({ rootState }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/dashboard/task/all/`
                )
                .then((res) => {
                    // console.log(res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        addReminder: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/dashboard/task/create/`,
                    payload
                )
                .then((res) => {
                    console.log(res);
                    return res;
                })
                // .catch((error) => {
                //     getMessageFromError(error);
                // });
        },

        updateReminder: ({ rootState }, payload) => {
            let id = payload.id;
            delete payload.id;
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/dashboard/task/update/${id}/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        deleteReminder: ({ rootState }, payload) => {
            return axios
                .delete(
                    `/${rootState.auth.organization.organization}/dashboard/task/delete/${payload.id}/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchPinnedReport: ({ rootState})=>{
            return axios.get(`${rootState.auth.organization.organization}/dashboard/pinned-report/?page_size=6&page=1`)
            .then((res)=>{
                return res;
            })
        },
        fetchchartData:({rootState},payload)=>{
            return axios.post(`/${rootState.auth.organization.organization}/reports/data/?action=generate`,payload)
            .then((res)=>{
                return res
            })
        }
    },
    mutations: {
        // stats
        setStats: (state, payload) => {
            state.states = payload;
        },
        clearStats: (state) => {
            state.states = [];
        },
        reset: (state) => {
            state.states = [];
        },
    },
};
