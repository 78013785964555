import { $notify, getMessageFromError } from "@/plugins/Helpers";

export default {
    state: {
        data: {},
        prefixes: [],
        employmentTypes: [],
        permissions: {},
        resize: false,
    },
    getters: {
        user: (state) => {
            return state.data;
        },
    },
    mutations: {
        setData: (state, data) => {
            state.data = data;
        },
        setPrefixes: (state, data) => {
            state.prefixes = data;
        },
        setEmploymentTypes: (state, data) => {
            state.employmentTypes = data;
        },
        clearPrefixes: (state) => {
            state.prefixes = [];
        },
        setPermissions: (state, data) => {
            state.permissions = data;
        },
        setResize: (state, payload) => {
            state.resize = payload;
        },
        reset: (state) => {
            state.data = {};
            state.prefixes = [];
            state.employmentTypes = [];
            state.permissions = {};
            state.resize = false;
        },
    },
    actions: {
        fetchPrefixes: ({ rootState, commit }) => {
            return axios
                .get(`${rootState.auth.organization.organization}/peoples/prefix/all/`)
                .then((res) => {
                    res.map((item) => {
                        item.label = item.name;
                        item.value = item.name;
                    });
                    commit("setPrefixes", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        createPrefix: ({ rootState }, payload) => {
            return axios
                .post(`/${rootState.auth.organization.organization}/peoples/prefix/create/`, payload)
                .then((res) => {
                    // res.results.map((item) => {
                    //     item.label = item.name;
                    //     item.value = item.name;
                    // });
                    // commit("setPrefixes", res.results);
                    $notify.success("Prefix create successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchEmploymentTypes: ({ commit }) => {
            return axios
                .get("/employment-types/all/")
                .then((res) => {
                    res.results.map((item) => {
                        item.label = item.name;
                        item.value = item.id;
                    });
                    commit("setEmploymentTypes", res.results);
                    return res.results;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        fetch: ({ commit }) => {
            return axios
                .get(`/user/details/`)
                .then((res) => {
                    commit("setData", res.data);
                    sessionStorage.setItem(
                        "access_expiration_delta",
                        res.data.access_expiration_delta
                    );
                    sessionStorage.setItem(
                        "org_info",
                        JSON.stringify(res.data.organization_profiles[0])
                    );
                    sessionStorage.setItem("user", JSON.stringify(res.data));
                    return res.data;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        updatePermissions: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization?.organization}/permission/module-wise/update/${payload.id}/`,
                    payload
                )
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchPermissions: ({ commit, rootState }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/permission/details/`
                )
                .then((res) => {
                    commit("setPermissions", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
};
