import {
    $getFormattedAmount,
    $getFormattedDate,
    $hasPermission,
    $orgCurrency,
    $orgDateFormat,
    $orgTimezone,
    getMessageFromError,
} from "@/plugins/Helpers";
import { modules } from "@/enum/UserPermissions";
import moment from "moment";
import { store } from "@/store";

export default {
    install: (app) => {
        app.config.globalProperties.$auth = store.state.auth;
        app.config.globalProperties.$hasPermission = $hasPermission;
        app.config.globalProperties.$modules = modules;
        app.config.globalProperties.$getMessageFromError = getMessageFromError;
        app.config.globalProperties.$moment = moment;
        app.config.globalProperties.$syncDrive = (module) => {
            store.dispatch("google/syncDrive", module);
        };
        app.config.globalProperties.$syncDropbox = (module) => {
            store.dispatch("dropbox/syncDropbox", module);
        };
        app.config.globalProperties.$getFormattedDate = $getFormattedDate;
        app.config.globalProperties.$getFormattedAmount = $getFormattedAmount;

        app.config.globalProperties.$orgDateFormat = $orgDateFormat;
        app.config.globalProperties.$orgCurrency = $orgCurrency;
        app.config.globalProperties.$orgTimezone = $orgTimezone;
    },
};
