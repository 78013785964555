export default {
    namespaced: true,
    state: {
        partnershipType: {},
        partnershipCategory: {},
        outsideExpertType: {},
        outsideExpertCategory: {},
    },
    actions: {
        fetchPartnershipType: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/partnerships/chart/partnership/type/report/`
                )
                .then((res) => {
                    commit("setPartnershipType", res);
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchPartnershipCategory: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/partnerships/chart/partnership/entity/report/`
                )
                .then((res) => {
                    commit("setPartnershipCategory", res);
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        fetchOutsideExpertType: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/partnerships/chart/outside/experts/type/report/`
                )
                .then((res) => {
                    commit("setOutsideExpertType", res);
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchOutsideExpertCategory: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/partnerships/chart/outside/experts/entity/report/`
                )
                .then((res) => {
                    commit("setOutsideExpertCategory", res);
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mutations: {
        setPartnershipType: (state, payload) => {
            state.partnershipType = payload;
        },
        setPartnershipCategory: (state, payload) => {
            state.partnershipCategory = payload;
        },
        setOutsideExpertType: (state, payload) => {
            state.outsideExpertType = payload;
        },
        setOutsideExpertCategory: (state, payload) => {
            state.outsideExpertCategory = payload;
        },
        reset: (state) => {
            state.partnershipType = {};
            state.partnershipCategory = {};
            state.outsideExpertType = {};
            state.outsideExpertCategory = {};
        },
    },
};
