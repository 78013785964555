import { store } from "@/store";
import { $alert } from "@/plugins/Helpers";
import router from "@/router";

const events = [
    "mouseup",
    "keydown",
    "scroll",
    "mousemove",
    "click",
    "mousedown",
    "mouseover",
    "mouseout",
    "change",
    "load",
    "touch",
    "touchmove",
    "resize",
    "drag",
    "drop",
];

let timer = null;

async function setSessionTimers() {
    await clearTimeout(timer);
    if (!store.state.auth.tokenTTL) return;
    if (
        router.currentRoute.value?.path === "/registration" ||
        router.currentRoute.value?.path === "/login"
    )
        return;

    timer = setTimeout(() => {
        startCountdown();
    }, Number(store.state.auth.tokenTTL) * 1000);
}

function startCountdown() {
    $alert
        .confirm({
            title: "Stay logged in?",
            text: "",
            showCancelButton: true,
            confirmButtonColor: "#5571d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            reverseButtons: true,
            timer: 10000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            willOpen() {
                removeEvents();
            },
            willClose: () => {
                addEvents();
            },
        })
        .then((result) => {
            if (result.isConfirmed) {
                return store.dispatch("auth/refreshToken");
            } else {
                return store.dispatch("auth/logout");
            }
        });
}

const addEvents = () => {
    // for production constraints only (temp)
    if (process.env.MODE === "production") return;

    events.forEach(function (event) {
        window.addEventListener(event, setSessionTimers);
    });
};

const removeEvents = () => {
    // for production constraints only (temp)
    if (process.env.MODE === "production") return;

    events.forEach(function (event) {
        window.removeEventListener(event, setSessionTimers);
    });
};

const trackUserActivities = async () => {
    // for production constraints only (temp)
    if (process.env.MODE === "production") return;

    console.log("track");
    await setSessionTimers();
    await removeEvents();
    addEvents();
    console.log("track end");
};

const destroyUserActivityTracker = async () => {
    // for production constraints only (temp)
    if (process.env.MODE === "production") return;

    console.log("destroy");
    await removeEvents();
    clearTimeout(timer);
    console.log("destroy end");
};

const initSessionTracker = () => {
    // for production constraints only (temp)
    if (process.env.MODE === "production") return;

    window.onload = async function () {
        console.log("onload");
        if (store.state.auth.isLoggedIn && store.state.auth.tokenTTL) {
            await trackUserActivities();
        } else {
            await destroyUserActivityTracker();
        }
    };

    window.addEventListener("beforeunload", async () => {
        console.log("onbeforeunload");
        await destroyUserActivityTracker();
    });

    // document.addEventListener("visibilitychange", async function () {
    //     if (document.hidden) {
    //         await destroyUserActivityTracker();
    //     } else {
    //         if (store.state.auth.isLoggedIn && store.state.auth.tokenTTL) {
    //             await trackUserActivities();
    //         }
    //     }
    // });
};

export {
    trackUserActivities,
    removeEvents,
    destroyUserActivityTracker,
    initSessionTracker,
};
