import { getMessageFromError } from "@/plugins/Helpers";

export default {
    namespaced: true,
    state: {
        data: {},
    },

    actions: {
        fetch: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/peoples/setting/`
                )
                .then((res) => {
                    commit("setData", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/peoples/setting/update/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setData: (state, payload) => {
            state.data = payload;
        },
        reset: (state) => {
            state.data = {};
        },
    },
};
