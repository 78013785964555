import { $notify, getMessageFromError, $setParams } from "@/plugins/Helpers";
const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
export default {
    state: {
        committees: [],
        committee: {},
    },
    actions: {
        fetchCommittees: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `${
                        rootState.auth.organization.organization
                    }/governance/committee/all/${$setParams(params)}`
                )
                .then((res) => {
                    commit("setCommittees", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `${rootState.auth.organization.organization}/governance/committee/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Committee created successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        update: ({ rootState }, payload) => {
            return axios
                .put(
                    `${rootState.auth.organization.organization}/governance/committee/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Committee updated successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setCommittees: (state, payload) => {
            state.committees = payload;
        },
        setCommittee: (state, payload) => {
            state.committee = payload;
        },
        clearCommittee: (state) => {
            state.committee = {};
        },
        reset: (state) => {
            state.committees = [];
            state.committee = {};
        },
    },
};
