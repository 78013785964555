import {notify} from "@kyvg/vue3-notification";
import router from "@/router";
import {$notify, getMessageFromError} from "@/plugins/Helpers";

export default {
    state: {
        user: null,
        isLoggedIn: false,
        tokenTTL: 0,
        token: null,
        organization: null,
        refreshTokenInterval: null,
        permissions: null,
        isRefreshing: false,
        check_user: {},
        organizationName: null,
    },
    actions: {
        login: ({commit, dispatch}, payload) => {
            return axios
                .post("/user/demo/token-auth/", payload)
                .then(async (response) => {
                    if (response.login) {
                        $notify.success("Welcome to Quantibly");
                        commit("setToken", response.token);
                        await dispatch("fetchUser");
                        await dispatch("generateRefreshTokenLoop");
                        await router.push({name: "dashboard"});
                    } else {
                        commit(
                            "temp/setData",
                            {
                                user_full_name: "",
                                name: "",
                                email: payload.email,
                                type: "email",
                            },
                            {root: true}
                        );
                        await router.push({name: "email-verification"});
                    }
                    return response;
                });
        },

        emailTokenCreate: ({rootState}) => {
            let url = "/user/password-reset/";
            let type = "patch";

            if (
                rootState.temp.data?.type === "email" ||
                rootState.temp.data?.type === "update-email"
            ) {
                url = "/user/email-token-create/";
                type = "post";
            }

            const data = {email: rootState.temp.data?.email};
            return axios[type](url, data).then((res) => {
                return res;
            });
        },

        tokenVerification: ({rootState, commit, dispatch}, token) => {
            const data = {
                email: rootState.temp.data?.email,
                token: token,
            };

            let url = "/user/forgot-password-token-verify/";

            if (rootState.temp.data?.type === "email") {
                url = "/user/email-token-verify/";
            }

            return axios.put(url, data).then(async (res) => {
                if (res.verified) {
                    $notify.success("Token verified successfully");
                    commit("setToken", res.token);
                    await dispatch("fetchUser");
                    commit("temp/clearData", null, {root: true});
                    await router.push({name: "dashboard"});
                }

                return res;
            });
        },

        setPassword: ({commit, rootState}, payload) => {
            payload = {...payload, ...rootState.temp.data};

            let url = "/user/password-set/";
            let type = "put";

            if (rootState.temp.data?.type === "email") {
                url = "/organization/create/";
                type = "post";
            }
            console.log('from store', payload)
            return axios[type](url, payload).then(() => {
                notify({
                    title: "Password set successfully.",
                    text: "Please login to continue.",
                    type: "success",
                    duration: 10000,
                });
                commit("temp/clearData", null, {root: true});
                return router.push({name: "login"});
            });
        },

        fetchUser: ({commit}) => {
            return axios.get("/user/details/").then((res) => {
                const {
                    organization_profiles,
                    access_expiration_delta,
                    ...userData
                } = res;
                commit("setUser", {
                    ...userData,
                    ...organization_profiles[0]?.user_info,
                });
                commit("setTokenTTL", access_expiration_delta);
                commit("setOrganization", organization_profiles[0]);
                commit("setPermissions", organization_profiles[0]?.permissions);
                commit("setOrganizationName", organization_profiles[0]?.name);
                // console.log(res);
            });
        },

        createOrganization: ({commit}, payload) => {
            return axios
                .post("/user/social-auth/organization-create/", payload)
                .then(() => {
                    commit("temp/clearData", null, {root: true});
                });
        },

        refreshToken: ({commit, state, dispatch}) => {
            return axios
                .post(
                    `/user/token/refresh/`,
                    {
                        refresh: state.token.refresh,
                    },
                    {Authorization: null}
                )
                .then((res) => {
                    commit("setToken", res);
                    dispatch("generateRefreshTokenLoop");
                })
                .catch(() => {
                    dispatch("localLogout");
                    $notify.error("Session Expired, Please login again.");
                });
        },

        generateRefreshTokenLoop: ({state, dispatch, commit}) => {
            commit("clearRefreshTokenLoop");
            // refresh token interval
            state.refreshTokenInterval = setInterval(async () => {
                await dispatch("refreshToken");
            }, 270000);
            return state.refreshTokenInterval;
        },

        destroyToken: ({state, dispatch}) => {
            if (!state.token || !state.token.refresh) {
                dispatch("localLogout");
            }
            return axios.post(`/user/token-invalidate/`, {
                refresh_token: state.token.refresh,
            });
        },

        localLogout: async ({dispatch, state, commit}) => {
            clearInterval(state.refreshTokenInterval);
            commit("reset");
            dispatch("clearStore", null, {root: true});
            axios.defaults.headers.common["Authorization"] = null;
            // destroyUserActivityTracker();
            await router.push("/login");
        },

        logout: async ({dispatch}) => {
            await dispatch("destroyToken")
                .then(() => {
                    dispatch("localLogout");
                })
                .catch((err) => {
                    getMessageFromError(err);
                });
        },
    },
    mutations: {
        setUser: (state, data) => {
            state.user = data;
            state.user.full_name = "";

            if (state.user.prefix_name) {
                state.user.full_name += state.user.prefix_name + " ";
            }
            if (state.user.first_name) {
                state.user.full_name += state.user.first_name + " ";
            }
            if (state.user.middle_name) {
                state.user.full_name += state.user.middle_name + " ";
            }
            if (state.user.last_name) {
                state.user.full_name += state.user.last_name;
            }
        },
        clearUser: (state) => {
            state.user = {};
        },
        setTokenTTL: (state, ttl) => {
            state.tokenTTL = ttl;
        },
        clearTokenTTL: (state) => {
            state.tokenTTL = 0;
        },
        setOrganization: (state, organization) => {
            state.organization = organization;
        },
        clearOrganization: (state) => {
            state.organization = null;
        },
        setPermissions: (state, permissions) => {
            state.permissions = permissions;
        },
        clearPermissions: (state) => {
            state.permissions = null;
        },
        setToken: (state, tokens) => {
            state.token = tokens;
            axios.defaults.headers.common[
                "Authorization"
                ] = `JWT ${tokens.access}`;
            state.isLoggedIn = !!tokens.access;
        },
        setIsRefreshing: (state, value) => {
            state.isRefreshing = value;
        },
        clearRefreshTokenLoop: (state) => {
            clearInterval(state.refreshTokenInterval);
        },
        setRefreshTokenInterval: (state, data) => {
            state.refreshTokenInterval = data;
        },
        reset: (state) => {
            state.user = null;
            state.isLoggedIn = false;
            state.tokenTTL = 0;
            state.token = null;
            state.organization = null;
            state.refreshTokenInterval = null;
            state.permissions = null;
            state.check_user = {};
            state.organizationName = null
        },
        setOrganizationName: (state, data) => {
            state.organizationName = data;
        }
    },
};
