<template>
    <notifications classes="vue-notification min-w-[300px]" />
    <router-view v-if="!state.processing" />
</template>
<script>
import { onBeforeMount, provide, reactive, watch } from "vue";
import { useStore } from "vuex";
import { notify } from "@kyvg/vue3-notification";
import {
    destroyUserActivityTracker,
    trackUserActivities,
} from "@/plugins/sessionChecker";

export default {
    setup() {
        const store = useStore();
        provide("store", store);
        provide("notify", notify);
        const state = reactive({
            processing: false,
        });

        watch(
            () => store.state.auth.tokenTTL,
            (value) => {
                if (value && value > 0) {
                    trackUserActivities();
                } else {
                    destroyUserActivityTracker();
                }
            }
        );

        onBeforeMount(async () => {
            state.processing = true;
            // for old auth user store clear (temp)
            if (store.state.auth.authorizationToken) {
                await store.dispatch("auth/localLogout");
            }
            if (store.state.auth.token && !store.state.auth.token.refresh) {
                await store.dispatch("auth/localLogout");
            }

            if (store.state.auth.isLoggedIn) {
                await store.dispatch("auth/refreshToken");
            } else {
                store.commit("auth/clearRefreshTokenLoop");
            }
            state.processing = false;
        });

        return {
            state,
        };
    },
};
</script>
